import Spoiler from "@/components/Spoiler";
import cx from "classnames";
import Select from "@/elements/Select";
import { getVersionHistoryName, prevent } from "@/utils";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from '@/store';
import SwitchSliderTitle from "@/elements/SwitchSliderTitle";
import { historySetData } from "@/actions/projectHistory/historySetData";
import Tooltip from "@/elements/Tooltip";
import ButtonRt from "@/components/Helper/ButtonRt";
import { useDeviceContext } from "@/context/DeviceContext";
import { ProjectSection } from "@/config/const";
import { isEmpty } from "lodash";
import service from "@/services";
import { historySetVersions } from "@/actions/projectHistory/historySetVersions";
import { IProjectHistoryState } from "@/reducers/ProjectHistory";

interface IHistoryBlockProps {
  projectId: number;
  sectionCode: ProjectSection;
  isHide?: boolean;
}

const HistoryBlock = ({ projectId, sectionCode, isHide }: IHistoryBlockProps) => {
  const dispatch = useAppDispatch();
  const projectHistory = useAppSelector(state => state.ProjectHistory[projectId] || ({} as IProjectHistoryState));
  const { isDesktop } = useDeviceContext();

  useEffect(() => {
    if (isEmpty(projectHistory?.versions) && projectId) {
      service.get(`/project/versionHistory/${projectId}`).then(data => {
        dispatch(historySetVersions(projectId, data));
      });
    }
  }, [projectHistory, projectId]);

  const versionsForSelect = projectHistory?.versions?.map(item => ({
    value: item.projectVersionId,
    label: getVersionHistoryName(item),
  })) || [];

  const clear = () => {
    dispatch(historySetData(projectId, {
      ...projectHistory,
      firstVersionId: null,
      secondVersionId: null,
      isShowGanttDateEndInit: false
    }));
  }

  useEffect(() => {
    clear();

    return () => clear();
  }, []);

  const spoilerTitle = (
    <div>
      Сравнение версий в таблице <Tooltip text='Режим сравнения версий включается только при выборе обеих версий для сравнения' placement='top'/>
      {isDesktop && (
        <button className="link dashboard__card-title__reset" onClick={prevent(clear)}>
          Сбросить
        </button>
      )}
    </div>
  );

  return !isHide && (
    <Spoiler
      title={spoilerTitle}
      defaultState={false}
      className='filter'
    >
      <div className={cx('row')}>
        <div className='col-md-6'>
          <Select
            options={versionsForSelect}
            label="Первая версия для сравнения"
            value={projectHistory.firstVersionId}
            onChange={(val) => dispatch(historySetData(projectId, { ...projectHistory, firstVersionId: val }))}
            isClearable={true}
          />
        </div>
        <div className='col-md-6'>
          <Select
            options={versionsForSelect}
            label="Вторая версия для сравнения"
            value={projectHistory.secondVersionId}
            onChange={(val) => dispatch(historySetData(projectId, { ...projectHistory, secondVersionId: val }))}
            isClearable={true}
          />
        </div>
        {sectionCode === ProjectSection.WORKS && (
          <div className='col-md-6'>
            <SwitchSliderTitle
              title="Отображать базовую дату окончания на графике"
              checked={projectHistory.isShowGanttDateEndInit}
              onChange={(val) => dispatch(historySetData(projectId, { ...projectHistory, isShowGanttDateEndInit: val }))}
            />
          </div>
        )}
        {!isDesktop && (
          <div className={cx('col-md-6', 'workList_filters__item')}>
            <ButtonRt
              type="outline"
              onClick={prevent(clear)}
              className="workList_filters__clear"
            >
              Сбросить
            </ButtonRt>
          </div>
        )}
      </div>
    </Spoiler>
  );
}

export default HistoryBlock;