import './index.scss';
import cx from 'classnames';
import { isEmpty, isFunction, max, range } from 'lodash';
import React, { Fragment, useEffect, useMemo } from "react";
import { emptyRenderer, useExpanded, useGroupBy, useSortBy, useTable } from 'react-table';
import { useDeviceContext } from "@/context/DeviceContext";
import ButtonSimple from '@/elements/ButtonSimple';
import { updateByRowAndColumn } from '@/components/TableBeta/utils';
import TableMobile from "@/components/TableMobile";
import SortButton from "./SortButton";
import ToggleButton from "./ToggleButton";
import SortButtonBoth from "@/components/TableBeta/SortButtonBoth";
import { prevent } from '@/utils';

export const BOOL_SORT_TYPE = ((a, b, id) => {
  if (a.original[id] > b.original[id]) return -1;
  if (b.original[id] > a.original[id]) return 1;
});

const TableBeta = ({
  columns,
  data,
  groupBy = [],
  hiddenColumns = [],
  big = false,
  hideFooter = false,
  className,
  mobileClassName = undefined,
  props = {},
  withMobile = false,
  alwaysMobile = false,
  setData = undefined,
  getEmptyRow = undefined,
  showSort = false,
  required = false,
  requiredNoEmptyRows = false,
  requiredAtLeastOne = false,
  description = undefined,
}: {
  columns: any[];
  data: any[];
  groupBy?: string[];
  hiddenColumns?: string[];
  big?: boolean;
  hideFooter?: boolean;
  className?: string;
  mobileClassName?: string;
  props?: any;
  withMobile?: boolean;
  alwaysMobile?: boolean;
  setData?: any;
  getEmptyRow?: any;
  showSort?: boolean;
  required?: boolean | 'red' | 'default';
  requiredNoEmptyRows?: boolean;
  requiredAtLeastOne?: boolean;
  description?: string;
}) => {
  const { isMobile } = useDeviceContext();
  const dataLocal = useMemo(() => {
    if (props.withFilterRemoved) {
      return data?.filter(item => !item.isRemove) || [];
    }

    return data || [];
  }, [data, props.withFilterRemoved]);

  const getRowId = React.useCallback((_, index) => {
    return index;
  }, []);

  const removeRowByIndex = (rowIndex: number) => {
    setData(oldData => oldData.filter((_, index) => index !== rowIndex));
  };

  const addRow = () => {
    setData(oldData => ([
      ...(oldData || []),
      getEmptyRow(),
    ]));
  };

  const info = useTable({
    columns,
    data: dataLocal,
    initialState: {
      groupBy,
      hiddenColumns
    },
    getRowId,
    props,
    setData,
    setDataById: (rowIndex, columnId, value) => {
      return updateByRowAndColumn(setData, rowIndex, columnId, value);
    },
    removeRowByIndex,
  }, useGroupBy, useSortBy, useExpanded);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    toggleAllRowsExpanded,
  } = info;

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [dataLocal]);

  const maxColumns = max<number>(headerGroups.map(item => item.headers.filter(column => !column.isHide).length));

  const checkIncorrectData = () => {
    const requiredAccessors = columns
      .filter(value => value.required)
      .map(value => value.accessor);
    if (requiredAtLeastOne
      && dataLocal.filter(value => requiredAccessors
        .filter(accessor => value[accessor])
        .length === requiredAccessors.length).length === 0) {
      return true;
    }
    return dataLocal
      .filter(value => !value.isRemove)
      .find(value =>
        requiredAccessors.find(accessor => !value[accessor])
        && (requiredNoEmptyRows || requiredAccessors.find(accessor => value[accessor])));
  }

  const getTextStyle = () => checkIncorrectData() && required === 'red'
    ? {color: 'red'}
    : {};

  const isRequired = (required && (requiredAtLeastOne || dataLocal.length > 0));
  const desc = [];
  if (isRequired) {
    desc.push('Обязательно к заполнению');
  }
  if (description) {
    desc.push(description);
  }

  if ((isMobile && withMobile) || alwaysMobile) {
    return (
      <div>
        {!isEmpty(desc) && (
          <div className='input-title__description'
               style={{paddingLeft: '0px', marginBottom: '-1rem', ...getTextStyle()}}>
            {desc.join('. ')}
          </div>
        )}
        <TableMobile info={info} alwaysMobile={alwaysMobile} mobileClassName={mobileClassName}/>
        <div className='display-flex-direction-row gap-3 marginBottom-30'>
          {props.withAddButton && props.isAcceptEdit && (
            <ButtonSimple style={getTextStyle()}
                          children={props.addButtonTitle ?? 'Добавить'}
                          onClick={prevent(addRow)}/>
          )}
          {props.additionalButtons?.length > 0 && (
            props.additionalButtons
              .map((item, index) => item?.alwaysShow || props.isAcceptEdit
                ? <ButtonSimple key={index} style={getTextStyle()} children={item.title} onClick={item.func}/>
                : null)
          )}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {!isEmpty(desc) && (
        <div className='input-title__description' style={{paddingLeft: '0px', ...getTextStyle()}}>
          {desc.join('. ')}
        </div>
      )}
      <div className={cx("dashboard-result-table__container contrast", className, {mini: !big})}>
        <table {...getTableProps()} className="table">
          <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.filter(column => !column.isHide).map(column => {
                const style = {
                  ...(column.headerStyle || {}),
                  ...(column.getSortByToggleProps()?.style || {})
                };

                return (
                  <th {...column.getHeaderProps({
                    className: column.headerClassName,
                    ...column.getSortByToggleProps(),
                    style,
                  })} title="">
                    <div className='table-header'>
                      <div>
                        {column.render('Header')}
                      </div>
                      {!column.hideSort && (
                        <div>
                          {column.isSorted
                            ? <SortButton desc={column.isSortedDesc}/>
                            : showSort ? <SortButtonBoth/> : ''}
                        </div>
                      )}
                    </div>

                  </th>
                );
              })}
            </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {rows.length === 0 ? (
            <tr>
              {range(maxColumns).map((_, i) => (
                <td key={i}>
                  {'-'}
                </td>
              ))}
            </tr>
          ) : rows.map((row, i) => {
            prepareRow(row);

            if (row.isGrouped) {
              const cell = row.allCells.find(item => item.column.id === row.groupByID);
              const withExpanded = cell.column.withExpanded;

              return (
                <React.Fragment key={`row_${i}`}>
                  <tr className='spacer'></tr>
                  <tr {...row.getRowProps()} {...(withExpanded ? row.getToggleRowExpandedProps({
                    title: ''
                  }) : {})}>
                    <td colSpan={row.cells.length}>
                      <div className="flex-center-vertical">
                        {cell ? (
                          cell.render("Cell")
                        ) : (
                          <b className="bold">{row.groupByVal}</b>
                        )}
                        {withExpanded && (
                          <ToggleButton isOpen={row.isExpanded}/>
                        )}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={`row_${i}`}>
                <tr className='spacer'></tr>
                <tr {...row.getRowProps()}>
                  {row.cells.filter(cell => !cell.column.isHide).map((cell) => {
                    let rowSpan = 1;
                    if (cell.column.enableRowSpan) {
                      const {rowSpanKey} = cell.column;
                      const rowSpanRows = rows.filter(item => item.original[rowSpanKey] === row.original[rowSpanKey]);
                      if (rowSpanRows[0].id === row.id) {
                        rowSpan = rowSpanRows.length * 2 - 1; //С учетом spacer строки
                      } else {
                        return null;
                      }
                    }
                    return (
                      <td
                        rowSpan={rowSpan}
                        {...cell.getCellProps([{
                          className: isFunction(cell.column.ClassName) ? cell.column.ClassName(cell) : cell.column.className,
                          style: isFunction(cell.column.Style) ? cell.column.Style(cell) : cell.column.style,
                        }])}
                        onDoubleClick={cell.column.onDoubleClick ? prevent(e => cell.column.onDoubleClick(cell.row)) : undefined}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              </React.Fragment>
            );
          })}
          </tbody>
          {!hideFooter && (
            <tfoot>
            {footerGroups.map(group => {
              const isShow = group.headers.some(item => !!item.Footer && item.Footer !== emptyRenderer);

              if (!isShow) {
                return null;
              }

              return (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                    ])}>{column.render('Footer')}</td>
                  ))}
                </tr>
              );
            })}
            </tfoot>
          )}
        </table>

        <div className='d-inline-flex gap-2'>
          {props.withAddButton && props.isAcceptEdit && (
            <ButtonSimple style={getTextStyle()}
                          children={props.addButtonTitle ?? 'Добавить'}
                          onClick={prevent(addRow)}/>
          )}
          {props.additionalButtons?.length > 0 && (
            props.additionalButtons
              .map((item, index) => item?.alwaysShow || props.isAcceptEdit
                ? <ButtonSimple key={index} style={getTextStyle()} children={item.title} onClick={item.func}/>
                : null)
          )}
        </div>

      </div>
    </Fragment>
  );
};

export default TableBeta;