import md5 from 'md5';
import { getDictObj } from '@/utils';

const arrayBufferToBase64 = (buffer) => {
  let temp = '';
  const arr = new Uint8Array(buffer);
  for (let i = 0; i < arr.length; i++) {
    temp += String.fromCharCode(arr[i]);
  }
  return btoa(temp);
};

export const createChunks = async (file, chunkSize) => {
  let startPointer = 0;
  let endPointer = file.size;
  let chunks = [];

  while (startPointer < endPointer) {
    let newStartPointer = startPointer + chunkSize;
    chunks.push(await ReadFileAsBytes(file.slice(startPointer, newStartPointer)).then(item => item.content));
    startPointer = newStartPointer;
  }

  return chunks;
}

export const prepareNewFile = async (item) => {
  const chunkSize = 5 * 1024 * 1024; //5MB
  const chunks = await createChunks(item.file, chunkSize);
  const fileContent = await item.file.text();
  return {
    name: item.name,
    typeId: item.typeId,
    chunks,
    chunkCount: chunks.length,
    md5: md5(fileContent),
  };
}

export const prepareFilesToSend = async (ownerId: number, externalId: number, items: any[], fileTypeDict: DictItem) => {
  const prepareOld = (item) => {
    return {
      fileContentId: item.fileContentId,
      name: item.name,
      typeId: item.typeId,
    };
  }
  const prepareLink = (item) => {
    return {
      externalLink: item.link,
      name: item.name,
      typeId: item.typeId,
    };
  }

  const prepareFile = async (item) => {
    let file;
    if (item.isLink) {
      file = prepareLink(item);
    } else if (item.file) {
      file = await prepareNewFile(item);
    } else if (item.fileContentId) {
      file = prepareOld(item);
    }

    const fileOwnerCode = getDictObj(fileTypeDict, file.typeId)?.section.owner.code;

    return {
      ...file,
      projectVersionId: fileOwnerCode === 'PROJECT' ? ownerId : null,
      ppmRequestId: fileOwnerCode === 'PPM_REQUEST' ? ownerId : null,
      externalId,
    };
  }

  return Promise.all(items.map(prepareFile));
}

interface IFileRequest {
  name: string,
  content: string,
}

const ReadFileAsBytes = (file) => new Promise<IFileRequest>((reslove, reject) => {
  if (!file) {
    reslove(null);
    return;
  }
  const reader = new FileReader();
  reader.onload = () => reslove({
    name: file.name,
    content: arrayBufferToBase64(reader.result),
  });
  reader.onerror = reject;
  reader.onabort = reject;
  reader.readAsArrayBuffer(file);
});

export default ReadFileAsBytes;