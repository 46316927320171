import React, { Fragment, useEffect, useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { useCheckRight, useShowModal, useStateWithKey, useUpdateProjectData } from "@/utils/hooks";
import Input from "@/elements/Input";
import i18n from "i18next";
import TextArea from "@/elements/TextArea";
import UserSearch from "@/components/UserSearch";
import DateRangeSelect from "@/elements/Input/DateRangeSelect";
import cx from "classnames";
import ProjectHeader from "@/components/ProjectHeader";
import ButtonBack from "@/components/Helper/ButtonBack";
import SaveCancel from "@/components/Helper/SaveCancel";
import service from "@/services";
import { withRouterParams } from "@/utils/router";
import { useAppSelector } from '@/store';
import {
  formatDateWithFormat,
  getDictCodeById,
  getDictForSelect, getDictObj,
  getProjectRoleOptionsList,
  momentToSelectDate, selectDateToMoment
} from "@/utils";
import moment from "moment";
import DocBlock from "@/components/Doc";
import { updateRequiredSections } from "@/actions/required/updateRequiredSections";
import { useDispatch } from "react-redux";
import Select from "@/elements/Select";
import { FORMAT_DATE_HUMAN } from "@/config/const";

const getMilestone = (milestoneId) => service.get(`/milestone/${milestoneId}`);
const postMilestone = (projectVersionId, data) => service.post(`/milestone/add/${projectVersionId}`, data);
const putMilestone = (milestoneId, data) => service.put(`/milestone/edit/${milestoneId}`, data);
const removeMilestone = (milestoneId) => service.remove(`/milestone/delete/${milestoneId}`);

const EditMilestone = ({
  projectVersionId,
  history,
  isEdit,
  milestoneId,
  isView = false,
  localData = undefined,
  setLocalData = undefined,
  openMilestoneIndex = undefined,
  setOpenMilestoneIndex = undefined
}
  : {
  projectVersionId: number,
  history: NavigateFunction,
  isEdit: boolean,
  milestoneId?: number,
  isView?: boolean,
  localData?: any,
  setLocalData?: any,
  openMilestoneIndex?: number,
  setOpenMilestoneIndex?: any,
}) => {
  const dataItem = localData?.find(item => item.rowNum === openMilestoneIndex);

  const [data, setDataByKey, setData] = useStateWithKey(
    dataItem
      ? { ...dataItem, milestoneStaticId: dataItem.milestoneId }
      : {
        id: null,
        name: null,
        comment: null,
        responsibleLogin: null,
        responsible: null,
        responsibleRoleId: null,
        datePlan: null,
        datePrediction: null,
        dateFact: null,
        removed: null,
        milestoneStaticId: null,
        isLocal: null,
        statusId: null
      });

  const projectData = useAppSelector(state => state.NewProject.newProjectData);
  const [roleDict, projectTypeDict, workStatusDict] = useAppSelector(state => [
    state.dict.roles,
    state.dict.projectTypes,
    state.dict.workStatus,
  ]);
  const checkRight = useCheckRight();
  const doc = React.useRef<any>();
  const showModal = useShowModal();
  const dispatch = useDispatch();
  const [accentRequiredOnSave, setAccentRequiredOnSave] = useState(false);
  const isRequiredAccent = useAppSelector(state => state.Required[projectVersionId]?.accent);
  const updateProjectData = useUpdateProjectData();

  useEffect(() => {
    if (milestoneId) {
      getMilestone(milestoneId).then(milestone => {
        updateData(milestone);
      });
    }
  }, [milestoneId]);

  useEffect(() => {
    if (isView && !data.isLocal) {
      doc.current?.getFilesInfo(projectVersionId, data.milestoneStaticId);
    }
  }, [data]);

  const setResponsible = (responsible) => {
    setData(prevState => ({ ...prevState, responsible: responsible, responsibleLogin: responsible.value }));
  }

  const updateData = (milestone) => {
    setData({
      id: isView ? milestone.id : null,
      name: milestone.name,
      comment: milestone.comment,
      responsibleLogin: milestone.responsible?.login,
      responsible: milestone.responsible,
      responsibleRoleId: milestone.responsibleRoleId,
      datePlan: milestone.datePlan,
      dateFact: milestone.dateFact,
      milestoneStaticId: milestone.milestoneId,
      isLocal: milestone.isLocal,
      isSystem: milestone.isSystem,
      isExtreme: milestone.isExtreme,
      statusId: milestone.statusId
    });
  }

  const goBack = () => {
    history(`/${i18n.t('base')}/${projectVersionId}/work`, { state: { disableBlock: true } });
  }

  const save = () => {
    if (!projectData.isSkipCreateSystemMilestoneAndCheck
      && ['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT']
        .includes(getDictCodeById(workStatusDict, data.statusId))
      && doc.current?.isEmptyWithOld()) {
      showModal('Внимание. Требуется приложить подтверждающие документы',
        true);
      return;
    }
    if (isView) {
      setLocalData(prevData => {
        prevData[openMilestoneIndex - 1] = data;
        return [...prevData];
      });
      if (!data.isLocal) {
        getMilestone(data.id)
          .then(serverMilestone => {
            doc.current?.sendFiles(projectVersionId, serverMilestone.milestoneId);
          })
          .then(() => updateProjectData(projectVersionId))
          .then(_ => setOpenMilestoneIndex && setOpenMilestoneIndex(null));
      } else {
        setOpenMilestoneIndex && setOpenMilestoneIndex(null);
      }
    } else if (isEdit && milestoneId) {
      putMilestone(milestoneId, data)
        .then(serverMilestone => doc.current?.sendFiles(projectVersionId, serverMilestone.milestoneId))
        .then(() => updateProjectData(projectVersionId))
        .then(goBack)
        .catch(() => setAccentRequiredOnSave(true))
        .finally(() => dispatch(updateRequiredSections(projectVersionId)));
    } else {
      postMilestone(projectVersionId, data)
        .then(serverMilestone => doc.current?.sendFiles(projectVersionId, serverMilestone.milestoneId))
        .then(() => updateProjectData(projectVersionId))
        .then(goBack)
        .catch(() => setAccentRequiredOnSave(true))
        .finally(() => dispatch(updateRequiredSections(projectVersionId)));
    }
  }

  const remove = () => {
    if (isView) {
      setLocalData(prevData => {
        prevData[openMilestoneIndex - 1] = { ...data, removed: true };
        return [...prevData];
      });
      setOpenMilestoneIndex && setOpenMilestoneIndex(null);
    } else if (isEdit) {
      removeMilestone(milestoneId).then(goBack);
    }
  }

  const redRequired = accentRequiredOnSave || isRequiredAccent ? 'red' : 'default';

  const getDocRequired = () => redRequired === 'red' && !doc.current?.isEmptyWithOld() ? 'default' : redRequired;

  const projectType = getDictCodeById(projectTypeDict, projectData?.typeId);

  const setDateFactToday = (newStatusId) => {
    const workStatusCode = getDictObj(workStatusDict, newStatusId)?.code;
    if (!['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(workStatusCode) && data.dateFact) {
      setDataByKey('dateFact', null);
    } else if (['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(workStatusCode) && !data.dateFact) {
      const now = moment();
      const dateStartOrNow = data.dateStart ? selectDateToMoment(data.dateStart) : now;
      const dateEndFact = moment.max(dateStartOrNow, now);
      setDataByKey('dateFact', momentToSelectDate(dateEndFact));
    }
  }

  return <Fragment>
    {!isView && <ButtonBack to={`/${i18n.t('base')}/${projectVersionId}/risk`}/>}
    <div className="wrapper-option">
      {!isView && <ProjectHeader title={`${isEdit ? 'Редактирование' : 'Добавление'} Контрольной точки`}/>}
      <div className="form-felix">
        <div className="marginBottom-20">
          <Input
            label={i18n.t('ProjectMilestoneWeb.name')}
            value={data.name}
            onChange={(_, val) => setDataByKey('name', val)}
            required={redRequired}
            disabled={!projectData.edit || data.isExtreme}
          />

          {!data.isExtreme && <TextArea
              label={i18n.t('ProjectMilestoneWeb.comment')}
              value={data.comment}
              onChange={(_, val) => setDataByKey('comment', val)}
              disabled={!projectData.edit || data.isExtreme}
          />}

          {!data.isExtreme && <UserSearch
              onChange={val => setResponsible(val)}
              value={data.responsible}
              label={i18n.t('ProjectMilestoneWeb.responsible')}
              required={redRequired}
              disabled={!projectData.edit}
          />}

          {!data.isExtreme && <Select
              required={redRequired}
              options={getProjectRoleOptionsList(roleDict, projectType)}
              label={i18n.t('ProjectMilestoneWeb.responsibleRoleId')}
              value={data.responsibleRoleId}
              onChange={(val) => setDataByKey('responsibleRoleId', val)}
              disabled={!projectData.edit}
          />}

          <Select
            required={redRequired}
            options={getDictForSelect(workStatusDict, item => item.name,
              item => item.isMilestone)}
            label={i18n.t('ProjectMilestoneWeb.statusId')}
            value={data.statusId}
            name="statusId"
            onChange={(val) => {
              setDataByKey('statusId', val);
              setTimeout(() => setDateFactToday(val), 200);
            }}
            description={data.dateUpdateStatus ? `Последнее изменение статуса ${formatDateWithFormat(data.dateUpdateStatus, FORMAT_DATE_HUMAN)}` : null}
          />

          <div className='marginBottom-10'/>

          <div className="row">
            <div className="col-md-12">
              <div className="mb-3 form-group">
                <h3 className={cx('h3-felix', 'workList__contentTitle')}>Сроки выполнения</h3>
              </div>
            </div>

            <div className={cx('col-md-12', 'workList__dateInput')}>
              <DateRangeSelect
                value={data.datePlan}
                onChange={(val) => setDataByKey('datePlan', val)}
                label={i18n.t('ProjectMilestoneWeb.datePlan')}
                isClearable={true}
                isPortal={true}
                portalScrollClasses=".main-panel, .gantt-edit__container"
                required={redRequired}
                disabled={!projectData.edit || data.isExtreme}
                minimumDate={projectData.dateStart}
                maximumDate={projectData.dateEnd}
              />
            </div>

            {!data.isExtreme && <div className={cx('col-md-12', 'workList__dateInput')}>
                <DateRangeSelect
                    value={data.dateFact}
                    onChange={(val) => setDataByKey('dateFact', val)}
                    label={i18n.t('ProjectMilestoneWeb.dateFact')}
                    isClearable={true}
                    isPortal={true}
                    portalScrollClasses=".main-panel, .gantt-edit__container"
                    minimumDate={data.datePlan || projectData.dateStart}
                    maximumDate={projectData.dateEnd}
                />
            </div>}

          </div>

          {!data.isLocal && !data.isExtreme &&
              <DocBlock
                  required={data.dateFact ? getDocRequired() : null}
                  isAcceptDelete={projectData.edit}
                  isEdit={true}
                  typeCode='WORK'
                  projectId={projectVersionId}
                  ref={doc}
                  otherId={data.milestoneStaticId || null}
              />
          }

          <SaveCancel
            save={checkRight("EDIT_PROJECT") ? save : undefined}
            cancel={isView ? () => {
              setOpenMilestoneIndex && setOpenMilestoneIndex(null);
            } : goBack}
            remove={!data.isExtreme && checkRight("EDIT_PROJECT") && (isEdit || isView) ? remove : undefined}
            saveLabel={isEdit || isView ? 'Сохранить' : 'Добавить'}
          />
        </div>
      </div>
    </div>
  </Fragment>
}

export default withRouterParams(EditMilestone);