import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import Status from '@/components/Status';
import { useAppSelector } from '@/store';
import { useExtImport } from '@/utils/hooks';
import ExtendableWrapper from '@/components/ExtendableWrapper';

const HeaderLinks = ({hideExternalLinks = false}) => {
  const extModule = useExtImport<IExtendableProps>('components/ProjectHeader/HeaderLinksExt');

  const [projectData, properties] = useAppSelector(state => [
    state.NewProject.newProjectData,
    state.dict.properties,
  ]);

  return (
    <ExtendableWrapper {...extModule} props={{hideExternalLinks: hideExternalLinks}}>
      <Link to={`/${i18n.t('base')}/${projectData.id}/agreement`}>
        <Status
          data={{
            status: projectData.statusId,
            isClosed: projectData.isClosed,
            projectId: projectData.projectId,
          }}
          projectData={projectData}
        />
      </Link>
    </ExtendableWrapper>
  );
}

export default HeaderLinks;