import { Modal } from 'react-bootstrap';
import React from 'react';
import Select from '@/elements/Select';
import ButtonRt from '@/components/Helper/ButtonRt';
import { getDictForSelect, getProjectRoleOptionsList } from '@/utils';
import i18n from 'i18next';
import { useStateWithKey } from '@/utils/hooks';
import { useAppSelector } from '@/store';
import UserSearch from '@/components/UserSearch';
import { WorkStatus } from '@/config/const';

const getDefaultData = () => ({
  taskStatusId: null,
  milestoneStatusId: null,
  responsible: null,
  responsibleRoleId: null
});

const MultipleEditModal = ({ editModalData, onSave }) => {
  const [workStatusDict, rolesDict, projectTypeCode] = useAppSelector(state => [
    state.dict.workStatus,
    state.dict.roles,
    state.NewProject.newProjectData.projectTypeCode,
  ]);
  const [data, setDataByKey, setData] = useStateWithKey(getDefaultData())

  const onOk = () => {
    onSave(data);
    onClose();
  }

  const onClose = () => {
    setData(getDefaultData());
    editModalData.setIsShow(false);
  }

  return editModalData.isShow && (
    <Modal
      onHide={onClose}
      show={editModalData.isShow}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      size="lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="h3">Групповое изменение данных в блоках работ</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          isHide={!editModalData.isHasTask}
          options={getDictForSelect(workStatusDict,
            item => item.name,
              item => item.isWork && WorkStatus.SUCCESS !== item.code)}
          label='Статус задачи'
          value={data.taskStatusId}
          onChange={(val) => setDataByKey('taskStatusId', val)}
          description='Данный статус применится к выделенным задачам'
        />

        <Select
          isHide={!editModalData.isHasMilestone}
          options={getDictForSelect(workStatusDict,
            item => item.name,
              item => item.isMilestone && ![WorkStatus.MILESTONE_SUCCESS, WorkStatus.MILESTONE_SUCCESS_WITH_COMMENT].includes(WorkStatus[item.code]))}
          label='Статус контрольной точки'
          value={data.milestoneStatusId}
          onChange={(val) => setDataByKey('milestoneStatusId', val)}
          description='Данный статус применится к выделенным контрольным точкам'
        />

        <UserSearch
          onChange={val => setDataByKey('responsible', val)}
          value={data.responsible}
          label={i18n.t('workDataRequest.responsible')}
          isPortal
        />

        <Select
          options={getProjectRoleOptionsList(rolesDict, projectTypeCode)}
          label={i18n.t('workDataRequest.responsibleRoleId')}
          value={data.responsibleRoleId}
          onChange={(val) => setDataByKey('responsibleRoleId', val)}
          isPortal
        />
      </Modal.Body>

      <Modal.Footer>
        <div className='button-felix-group'>
          <ButtonRt onClick={onOk}>
            Сохранить
          </ButtonRt>
          <ButtonRt type="outline" onClick={onClose}>
            Отмена
          </ButtonRt>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default MultipleEditModal;