import React from 'react';
import cx from 'classnames';

const TabButtons = ({ 
  data, 
  value = "month", 
  onChange = (a: any, b: any) => {}, 
  className = undefined,
  itemClassName = undefined,
}) => {

  return (
    <div className={cx("tabs-button__container", className)}>
      { data.filter(item => !item.isDisabled).map(item => (
        <div key={item.value}
             className={cx("tabs-button__item", itemClassName, {'active': item.value === value })}
             style={item.style}
             onClick={() => onChange(item.value, item)}>{item.title}</div>
      )) }
    </div>
  )
}


export default TabButtons;
