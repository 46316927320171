import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import '@/assets/styles/base.scss';
import 'rc-tree-select/assets/index.less';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Main from '@/pages/Main';
import { Provider } from 'react-redux';
import moment from 'moment'
import 'moment/locale/ru';
import '@/config/localization';
import DeviceContextProvider from '@/context/DeviceContext';
import { store } from '@/store';

moment.defaultFormat = "DD.MM.YYYY HH:mm";
moment.locale('ru');

const rootElement = document.getElementById('root');

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <DeviceContextProvider>
          <Component />
        </DeviceContextProvider>
      </BrowserRouter>
    </Provider>,
    rootElement,
  );
};

const felixStart = () => renderApp(Main);
export default felixStart;