import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import EditItemLink from "@/components/Gantt/components/EditItemLink";
import { useWeekendUtil } from "@/components/Gantt/util/dateUtil";
import { checkNotBeforeParent, fixByLinks, fixGroup } from '@/components/Gantt/util/linkUtil';
import {
  findAndUpdate,
  flatten,
  getDateEndForDuration,
  getLinkKey,
  insertBeforeId,
  removeById,
  searchById
} from '@/components/Gantt/util/utils';
import ButtonContainer from "@/components/Helper/ButtonContainer";
import ButtonRt from "@/components/Helper/ButtonRt";
import EditBlockView from "@/pages/CreateProject/Blocks/Components/EditBlockView";
import { appendResponsibleFact, WORK_NEED_DOC_MESSAGE } from "@/pages/CreateProject/Blocks/EditBlock";
import { getProgress } from "@/pages/CreateProject/Blocks/Gantt";
import {
  checkRight as createCheckRight,
  getDictByCode,
  getDictObj,
  isEmptyValues,
  isNotEmptyValues,
} from '@/utils';
import { getPathByType } from "@/utils/project";
import { useAppSelector } from '@/store';
import { useShowModal } from "@/utils/hooks";
import { isEmpty, isEqual, uniqBy } from 'lodash';
import { UserRight } from "@/config/const";
import { getCalendarDuration, getDuration } from '@/pages/CreateProject/Blocks/utils';

export const setIsRemove = (item: GanttTableItem): GanttTableItem => ({
  ...item,
  isRemove: true
});

export const filterRemovedLinks = (links: GanttTableLink[], workId: number[]) =>
  links.filter(link => {
    return !(workId.includes(link.fromId) || workId.includes(link.toId));
  });

export const moveGroup = (result: GanttTableItem[], itemId: number, groupOldId: number | null, groupNewId: number | null) => {
  if ((groupOldId || null) !== (groupNewId || null)) {
    const item = searchById(result, itemId);

    if (isEmptyValues(groupOldId) && isNotEmptyValues(groupNewId)) {
      result = removeById(result, item.id);
      result = insertBeforeId(result, groupNewId, false, item, true, true);
    } else if (isNotEmptyValues(groupOldId) && isEmptyValues(groupNewId)) {
      result = removeById(result, item.id);
      result = insertBeforeId(result, groupOldId, true, item, true);
    } else {
      result = removeById(result, item.id);
      result = insertBeforeId(result, groupNewId, false, item, true, true);
    }
  }

  return result;
};

const EditItem = ({
  openWorkId,
  setOpenWorkId,
  data,
  links,
  updateData,
  readonly,
  linkReadonly,
  isOnlyLinkEdit = false,
  projectData,
  setExpanded,
}) => {
  const weekendUtil = useWeekendUtil();
  const showModal = useShowModal();
  const localDocs = useRef(null);
  const [localData, setLocalData] = useState<GanttTableItem>(null);
  const [localLink, setLocalLink] = useState<GanttTableLink[]>([]);
  const workStatusDict = useAppSelector(state => state.dict.workStatus);
  const projectStatusDict = useAppSelector(state => state.dict.status);
  const checkRight = useAppSelector(state => createCheckRight(state));
  const curUser = useAppSelector(state => state.Login.currentUser);

  const save = async () => {
    //Проверим не стала ли работа раньше родителя
    try {
      checkNotBeforeParent(localData, data, localLink, weekendUtil, workStatusDict, isOnlyLinkEdit);
    } catch (e) {
      showModal(e, true);
    }

    const fileItems = localDocs?.current?.getItems() || [];

    updateData((oldData: {
      work: GanttTableItem[],
      link: GanttTableLink[];
    }) => {
      let link = ({} as {
        groupOld: number | null;
        groupNew: number | null;
      });

      let isDateChanged = false;
      let result = findAndUpdate(oldData.work, localData.id, (oldItem) => {
        if (getDictObj(projectStatusDict, projectData.statusId)?.code !== 'DRAFT'
          && oldItem.statusId && localData.statusId
          && oldItem.statusId !== localData.statusId
          && ['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(getDictObj(workStatusDict, localData.statusId)?.code)
          && localDocs.current?.isEmpty()
          && isEmpty(localData.urlList.filter(url => !url.isRemoved))) {
          showModal(WORK_NEED_DOC_MESSAGE);
          return oldItem;
        }

        link = {
          groupOld: oldItem.workGroupId,
          groupNew: localData.workGroupId,
        };

        const oldDateEndForDuration = getDateEndForDuration(oldItem, workStatusDict);
        const dateEndForDuration = getDateEndForDuration(localData, workStatusDict);
        isDateChanged = oldItem.dateStart !== localData.dateStart || oldDateEndForDuration !== dateEndForDuration;

        return {
          ...localData,
          duration: getDuration(localData.dateStart, dateEndForDuration, weekendUtil),
          calendarDuration: getCalendarDuration(localData.dateStart, dateEndForDuration),
          progress: getProgress(oldData.work, localData),
          isLocalSaved: true,
          fileItems,
        };
      }, workStatusDict, weekendUtil);

      result = moveGroup(result, localData.id, link.groupOld, link.groupNew);

      const linkResult = uniqBy(localLink, getLinkKey);
      const isLinkChanged = !isEqual(oldData.link, linkResult);

      if (isDateChanged || isLinkChanged) {
        result = fixByLinks(localData.id, result, linkResult, weekendUtil, workStatusDict, !isDateChanged && isLinkChanged);
      }
      result = fixGroup(result, weekendUtil, workStatusDict);

      return {
        ...oldData,
        link: linkResult,
        work: result,
      };
    });

    if (localData.workGroupId) {
      setExpanded(oldExpanded => ({
        ...oldExpanded,
        [`${localData.workGroupId}`]: true,
      }));
    }

    close();
  };

  const close = () => setOpenWorkId(null);
  const remove = () => {
    updateData((oldData) => {
      let result = findAndUpdate(oldData.work, localData.id, (item) => {
        return {
          ...setIsRemove(item),
        };
      }, workStatusDict, weekendUtil);

      result = fixGroup(result, weekendUtil, workStatusDict);

      let linkResult = filterRemovedLinks(localLink, [localData.id]);

      return {
        ...oldData,
        link: linkResult,
        work: result
      };
    });
    close();
  };

  useEffect(() => {
    if (!openWorkId) {
      setLocalData(null);
      return;
    }

    const init = async () => {
      let item = searchById(data, openWorkId);
      item = await appendResponsibleFact(item, true);

      setLocalData(item);
      setLocalLink(links);

      if (localDocs?.current && !isEmpty(item.fileItems)) {
        localDocs.current.setItems(item.fileItems);
      }
    };

    init();
  }, [openWorkId, data, links]);

  useEffect(() => {
    if (openWorkId) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [openWorkId]);

  const closeOnEsc = useCallback((event) => {
    if (event.key === "Escape") {
      close();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", closeOnEsc, false);

    return () => {
      document.removeEventListener("keydown", closeOnEsc, false);
    };
  }, [closeOnEsc]);


  const isStatusActive = () => {
    return getDictByCode(projectStatusDict, 'RELEASE')?.id === projectData?.statusId;
  };

  const isEditRight = () => {
    if (isStatusActive() && checkRight("EDIT_PROJECT")) {
      return true;
    }

    return projectData.edit && checkRight("EDIT_PROJECT");
  };

  if (!localData) {
    return null;
  }

  const isEditWorkProgressActiveRight = checkRight(UserRight.EDIT_WORK_PROGRESS_RELEASE);
  const isEditUrlsRight = checkRight(UserRight.EDIT_WORK_URL);
  const isCurUserResponsible = localData.responsible?.id === curUser.id
    || localData.responsible?.id === curUser.deputyHost?.id;
  const isCanSave = !readonly && (!isOnlyLinkEdit || !linkReadonly) && !localData.isExtreme
    && (isEditRight() || isEditWorkProgressActiveRight || isEditUrlsRight || isCurUserResponsible);

  return (
    <div className="gantt-edit__container">
      {isOnlyLinkEdit ? (
        <h4 className="h4-felix">{`Связи блока работ "${localData.name}"`}</h4>
      ) : (
        <h4 className="h4-felix">{readonly || localData.isFromOtherProject ? 'Блок работ' : 'Редактирование'}</h4>
      )}

      <ButtonContainer>
        {isCanSave && (
          <ButtonRt children="Сохранить" onClick={save} />
        )}
        <ButtonRt children="Закрыть" type="outline" onClick={close} />
      </ButtonContainer>

      {isOnlyLinkEdit ? (
        <EditItemLink
          data={data}
          links={localLink}
          curWorkId={openWorkId}
          curWorkProjectId={localData.projectId}
          setLinks={setLocalLink}
          readonly={linkReadonly}
          projectId={projectData.projectId}
        />
      ) : (
        <>
          {localData.id > 0 && (
            <Link className="link active mb-2" to={`/${getPathByType(projectData.projectTypeCode || 'PROJECT')}/${localData.projectVersionId}/work/${localData.id}`}>
              Перейти в основную карточку
            </Link>
          )}
          <EditBlockView
            data={localData}
            setData={setLocalData}
            links={localLink}
            setLinks={setLocalLink}
            isEdit={localData.id > 0}
            projectVersionId={localData?.projectVersionId || projectData.id}
            getAllWork={(flatten(data) as Work[])}
            onCancel={close}
            onDeleteTask={remove}
            onSave={save}
            isFromGantt={true}
            isOnlyLinkEdit={isOnlyLinkEdit}
            readonly={(readonly || localData.isFromOtherProject)}
            linkReadonly={linkReadonly}
            docs={localDocs}
            isDeleteDisabled={localData.isRemoveDisabled}
          />
        </>
      )}
    </div>
  );
};

export default EditItem;