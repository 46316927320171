import { compact } from "lodash";
import { formatDate, getDict } from "@/utils";
import { createSVG } from "@/components/FrappeGantt/src/svg_utils";
import i18n from "i18next";

//Штуки для старого ганта

export type BarPosition = 'CENTER' | 'TOP' | 'BOTTOM';

interface IBarCreatorProps {
  className: string;
  labelClassName?: string;
  position?: BarPosition;
  isProgress?: boolean;
}

const changeBarPosition = (bar, position, labelClassName = '') => {
  const newY = bar.y - 6;
  const newHeight = bar.height + 12;
  const delimiter = 1;
  switch (position) {
    case "TOP": {
      bar.y = newY;
      bar.height = newHeight / 2 - delimiter;
      bar.changed = true;
      break;
    }
    case 'BOTTOM': {
      bar.y = newY + newHeight / 2 + delimiter * 2;
      bar.height = newHeight / 2 - delimiter;
      break;
    }
  }
  bar.labelClassName = labelClassName;
  bar.changed = true;
}

export const barCreator = ({ className, labelClassName, position, isProgress }: IBarCreatorProps) => {
  return (bar) => {
    if (!bar.changed) {
      changeBarPosition(bar, position, labelClassName);
    }

    let barParams = {
      x: bar.x,
      y: bar.y,
      width: isProgress ? bar.progress_width : bar.width,
      height: bar.height,
      rx: bar.corner_radius,
      ry: bar.corner_radius,
      class: className,
      append_to: bar.bar_group,
    }

    return createSVG('rect', barParams);
  }
}

export const milestoneBarCreator = ({ className, position }: IBarCreatorProps) => {
  return (bar) => {
    if (!bar.changed) {
      if (!bar.changed) {
        changeBarPosition(bar, position);
      }
    }

    let barParams = {
      x: bar.x,
      y: bar.y,
      width: bar.height,
      height: bar.height,
      rx: bar.corner_radius,
      ry: bar.corner_radius,
      class: className,
      append_to: bar.bar_group,
      transform: `rotate(45 ${bar.x + (bar.height / 2)} ${bar.y + (bar.height / 2)})`
    }

    return createSVG('rect', barParams);
  }
}

export const dateEndInitBarCreator = ({ className, position }: IBarCreatorProps) => {
  return (bar) => {
    if (!bar.changed) {
      if (!bar.changed) {
        changeBarPosition(bar, position);
      }
    }

    let barParams = {
      x: bar.x,
      y: bar.y,
      width: 5,
      height: bar.height,
      class: className,
      fill: '#FFA200',
      append_to: bar.bar_group,
    }

    return createSVG('rect', barParams);
  }
}

interface IPopupCreatorProps {
  statusDict: DictItem;
  projectBase?: string;
}

export const popupCreator = ({ statusDict, projectBase }: IPopupCreatorProps) => {
  return (task) => `
    <div class="title">
      <a target="_blank" href="/${projectBase || i18n.t('base')}/${task.rawData.projectVersionId}/work/${task.id}">${task.popupName || task.name}</a>
    </div>
    <div class="subtitle nowrap">
      ${compact([
      `${formatDate(task.start)} - ${formatDate(task.end)}`,
      task.rawData.responsible?.displayName,
      getDict(statusDict, task.rawData.statusId),
      task.rawData.results
    ]).map(item => `<div>${item}</div>`).join('')}
    </div>
  `;
}

export const dateEndInitPopupCreator = () => {
  return (task) => `
    <div class="subtitle nowrap">
      Базовый срок (план): ${formatDate(task.rawData.dateEndInitial)}
    </div>
  `;
}