import React, { Fragment, useEffect, useState } from "react";
import service from "@/services";
import UserSearch from "@/components/UserSearch";
import i18n from "i18next";
import Select from "@/elements/Select";
import { compact } from 'lodash';
import { useAppSelector } from '@/store';
import { getDictObj } from "@/utils";

const getUsersByRole = (roleId) => service.get(`/user/getAllByProjectRole/${roleId}`);

const UserCommandSearch = ({roleId, onChange, value, redRequired, disabled, props}
  : {roleId: number, onChange: any, value: any, redRequired?: any, disabled?: boolean, props?: any}) => {
  const [userOptions, setUserOptions] = useState([]);
  const roleDict = useAppSelector(state => state.dict.roles);
  const role = getDictObj(roleDict, roleId);
  const getUserRoleDescription = (login) => {
    const user = userOptions.find(user => user.login === login);
    if (!role.id) {
      return null;
    }
    return user?.roleComments?.find(roleComment => roleComment?.roleId
      === role.id)?.roleComment;
  }

  useEffect(() => {
    if (roleId) {
      getUsersByRole(roleId).then(setUserOptions);
    }
  }, [roleId]);

  return <Fragment>
    {userOptions.length > 0
      ? <Fragment>
        <Select
          required={redRequired}
          options={userOptions.map(user => ({
            label: compact([user.displayName, user.department, user.post]).join(' / '),
            value: user.login,
            description: getUserRoleDescription(user.login)
          }))}
          onChange={(val) => {
            const user = userOptions.find(user => user.login === val);
            onChange({
              value: user.login,
              isLdap: user.isLdap,
              displayName: user.displayName,
              id: user.id,
              label: compact([user.displayName, user.department, user.post]).join(' / '),
              functionCode: user.functionCode
            })
          }}
          value={value?.value}
          label={i18n.t('employeeDataCreate.userLogin')}
          disabled={!roleId || disabled}
          descriptionInOptions
          descriptionNewLine
          description={value?.value && getUserRoleDescription(value?.value)}
          {...props}
        />
      </Fragment>
      : <UserSearch
        required={redRequired}
        onChange={(val) => onChange(val)}
        value={value}
        label={i18n.t('employeeDataCreate.userLogin')}
        name="userLogin"
        disabled={!roleId || disabled}
        {...props}
      />}
  </Fragment>
}

export default UserCommandSearch;