import React, { useEffect, useState } from 'react';
import { getGroupData, prepareDataForTable } from "@/pages/CreateProject/Agreement/helpers";
import SpoilerTableDiff from "@/pages/CreateProject/Agreement/Block/SpoilerTableDiff";
import TableDiff from "@/pages/CreateProject/Agreement/Block/TableDiff";
import { isArray, isEmpty } from 'lodash';
import Spoiler from "@/components/Spoiler";
import cx from "classnames";

interface IBlockProps {
  title: string;
  data: any[];
  dataSecond: any[];
  isOnlyUpdate?: boolean;
  isDiff?: boolean;
  keyDiff?: string;
  subDataKeyDiff?: string;
  newTitle?: (string) => string;
  isGroup?: boolean;
  isGroupAsTable?: boolean;
  isFirstAgree?: boolean
}

const Block = ({
  title,
  data,
  dataSecond,
  isOnlyUpdate,
  isDiff,
  keyDiff,
  subDataKeyDiff,
  newTitle,
  isGroup,
  isFirstAgree
}: IBlockProps) => {
  const [groupedData, setGroupedData] = useState([]);

  useEffect(() => {
    if (isEmpty(data) && isEmpty(dataSecond)) {
      return;
    }

    if (isGroup) {
      setGroupedData(getGroupData(data, dataSecond, keyDiff).map(newData => {
        const { title, data, _raw } = newData;

        const isSubData = (data[0] && !isArray(data[0])) || (data[1] && !isArray(data[1]));
        let dataGroup;
        let dataTitle;
        let isSubDataHasDiff;
        if (isSubData) {
          dataGroup = prepareDataForTable(data[0]?.data, data[1]?.data, isDiff, isOnlyUpdate, subDataKeyDiff);
          isSubDataHasDiff = !isEmpty(dataGroup.filter(item => item.data[0] != item.data[1]))
          dataTitle = prepareDataForTable([{title, data: data[0]?.value}], [{title, data: data[1]?.value}], isDiff, isOnlyUpdate, keyDiff, isSubDataHasDiff);
        } else {
          dataGroup = prepareDataForTable(data[0], data[1], isDiff, isOnlyUpdate, keyDiff);
        }

        return {
          title,
          dataTitle,
          data: dataGroup,
          isEmpty: !dataGroup.length && !isSubData,
          isSubDataHasDiff,
        }
      }));
    } else {
      setGroupedData(prepareDataForTable(data, dataSecond, isDiff, isOnlyUpdate, keyDiff));
    }
  }, [data, dataSecond, keyDiff, isDiff, isOnlyUpdate]);

  if (isGroup && isEmpty(groupedData.filter(item => !item.isEmpty))) {
    return null;
  }
console.log(groupedData)
  return (
    <React.Fragment>
      <Spoiler title={title} defaultState={true}
               titleClassName={cx('version-block-title', 'version-block-title-color',
                 { 'first-agree': isFirstAgree })}>
        { isGroup ? groupedData.map((item, i) => (
          <SpoilerTableDiff key={`${item.title}-${i}-${isOnlyUpdate}`}
            title={newTitle ? newTitle(item.title) : item.title}
            dataTitle={item.dataTitle}
            data={item.data}
            isItemEmpty={item.isEmpty}
            isDiff={isDiff}
            isOnlyUpdate={isOnlyUpdate}
            defaultSpoilerState={!item.dataTitle}
            isFirstAgree={isFirstAgree}
            isSubDataHasDiff={item.isSubDataHasDiff}
          />
        )) : (
          <TableDiff
            data={groupedData}
            isOnlyUpdate={isOnlyUpdate}
            isDiff={isDiff}
            isFirstAgree={isFirstAgree}
          />
        ) }
      </Spoiler>
    </React.Fragment>
  );
}

export default Block;
