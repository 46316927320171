import i18n from "i18next";
import { cloneDeep, debounce, isEmpty, isEqual, minBy, uniqBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import GanttWithLoad, { prepareWorksForGantt } from "@/pages/CreateProject/Blocks/GanttWithLoad";
import AcceptDelete from '@/components/Helper/AcceptDelete';
import ButtonAppend from '@/components/Helper/ButtonAppend';
import withDeviceQuery from '@/hocs/withDeviceQuery';
import FilterWorks from '@/pages/CreateProject/Blocks/FilterWorks';
import Gantt from "@/pages/CreateProject/Blocks/Gantt";
import SpoilerResultWorks from '@/pages/CreateProject/Blocks/SpoilerResultWorks';
import TableResultWorks from '@/pages/CreateProject/Blocks/TableResultWorks';
import { workGroupSort } from '@/pages/CreateProject/Blocks/utils';
import TabButtons from "@/pages/Dashboard2/components/TabButtons";
import service from '@/services';
import { checkEdit, checkRight, getDictByCode, getDictCodeById, getDictObj, getFilterKey, importExt } from '@/utils';
import HistoryBlock from "@/pages/CreateProject/HistoryBlock";
import { ProjectSection, ProjectStatus, UserRight } from "@/config/const";
import { IProjectHistoryState } from "@/reducers/ProjectHistory";
import AddFromOtherProjects from "@/pages/CreateProject/Blocks/AddFromOtherProjects";
import { updateRequiredSections } from "@/actions/required/updateRequiredSections";
import { addUserValue } from "@/actions/addUserValue";
import Milestone, { milestoneDefaultFilter } from "@/pages/CreateProject/Blocks/Milestone/Milestone";
import FilterMilestone from "@/pages/CreateProject/Blocks/Milestone/FilterMilestone";
import services from "@/services";
import ButtonRtAccept from "@/components/Helper/ButtonRtAccept";
import { flatten, getGroupIds, getLinkKey, groupWorks, setAutoFakeIndex } from "@/components/Gantt/util/utils";
import { prepareWorkSave } from "@/pages/CreateProject/Blocks/EditBlock";
import ExtendableWrapper from '@/components/ExtendableWrapper';

export const workDataLoader = (id: number, props = {}): Promise<Work[]> =>
  service.get(`/work/project/${id}`, props);

export const workLinkLoader = (id: number): Promise<GanttTableLink[]> =>
  service.get(`/work/link/project/${id}`);

const getTableXls = (id: number, props = {}) => service.postDownload(`/work/projectxls/${id}`, props);
const getMilestoneXls = (projectVersionId, filter) =>
  service.postDownload(`/milestone/project/xls/${projectVersionId}`, filter);
export const removeWorkList = (worksId: number[]) => service.post(`/work/remove/list`, worksId);
export const unlinkWorkList = (projectId, data) => service.post(`/work/unlinkWorks/project/${projectId}`, data);

export const generateFromTemplate = (projectVersionId) => services
  .post(`/work/msproject/project/${projectVersionId}/xml/fromTemplate`);

export enum ViewType {
  GANTT = 'GANTT',
  TABLE = 'TABLE',
  LIST = 'LIST',
  MILESTONE = 'MILESTONE'
}

export const getDefaultFilter = () => ({
  dateStart: {
    from: null,
    to: null
  },
  dateEnd: {
    from: null,
    to: null
  },
  statusId: null,
  sortDataField: null,
  sortOrder: 'asc',
  responsible: null,
});

class WorkList extends React.Component<any, any> {
  debounceDataLoad: any;

  constructor(props) {
    super(props);

    const workAgreeTypeCode = this.props.projectData?.projectWorkAgreeTypeCode;

    this.state = {
      blocks: [],
      milestone: [],
      links: [],
      filterState: props.localStorage?.[getFilterKey()] || cloneDeep(getDefaultFilter()),
      milestoneFilterState: props.localStorage?.[`${getFilterKey()}-milestone`] || cloneDeep(milestoneDefaultFilter),
      historyFirstBlocks: [],
      historyFirstLinks: [],
      historySecondBlocks: [],
      historySecondLinks: [],
      isHistoryFirstLoad: false,
      isHistorySecondLoad: false,
      isLoad: false,
      viewType: props.localStorage?.[`card-${this.props.projectData?.id}-viewType`] || (workAgreeTypeCode === 'BY_MILESTONE'
        ? ViewType.MILESTONE
        : ViewType.GANTT),
      isFirstLoad: false,
      extModule: {},
    };

    this.debounceDataLoad = debounce(() => {
      this.getBlockFromServer();
    }, 500);
  }

  currentProjectHistory() {
    return this.props.projectHistory[this.props.projectData.projectId] || ({} as IProjectHistoryState);
  }

  updateExtModule = () => {
    importExt('pages/CreateProject/Blocks/WorkListExt').then(extModule => this.setState({ extModule }));
  }

  componentDidMount() {
    this.updateExtModule();
    this.getBlockFromServer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.currentProjectHistory().isEnabled && this.state.viewType !== ViewType.TABLE) {
      this.setState({ viewType: ViewType.TABLE });
    }

    if (!this.state.isFirstLoad && this.state.isLoad === false) {
      this.getBlockFromServer();
      return;
    }

    if (!isEqual(prevState.filterState, this.state.filterState)) {
      this.debounceDataLoad();
    }

    if (!this.state.isHistoryFirstLoad
      || !isEqual(prevProps.projectHistory[this.props.projectData.projectId]?.firstVersionId, this.currentProjectHistory().firstVersionId)) {
      this.getHistoryFirstDataFromServer();
    }

    if (!this.state.isHistorySecondLoad
      || !isEqual(prevProps.projectHistory[this.props.projectData.projectId]?.secondVersionId, this.currentProjectHistory().secondVersionId)) {
      this.getHistorySecondDataFromServer();
    }
  }

  editButton = (item) => {
    const { history, projectId } = this.props;
    history(`/${this.getProjectBase()}/${projectId}/work/${item.id}`);
  };

  getJiraUrl = (work) => {
    return `${this.props.dict.properties.data['jira.url']}/projects/${this.props.projectData.jiraCode}/issues/${work.jiraCode}`;
  };

  getDataWithParams = (id) => workDataLoader(id, {
    params: JSON.stringify({ ...this.state.filterState, withLinked: true }),
  });

  setWorks = (data, oldChecked = []) => {
    const sortedData = workGroupSort(data);
    this.setState({
      blocks: sortedData.map(item => ({
        ...item,
        isChecked: oldChecked.includes(item.id)
      })),
      isLoad: true,
    });
  };

  getBlockFromServer = () => {
    this.setState({ isFirstLoad: true });
    const oldChecked = this.state.blocks
      .filter(({ isChecked }) => isChecked)
      .map(({ id }) => id);

    this.getDataWithParams(this.props.projectId).then(data => {
      this.setWorks(data, oldChecked);
    });

    workLinkLoader(this.props.projectId).then(links => this.setState({ links }));

    this.getHistoryDataFromServer();
  };

  getHistoryDataFromServer = () => {
    this.getHistoryFirstDataFromServer();
    this.getHistorySecondDataFromServer();
  }

  getHistoryFirstDataFromServer = () => {
    this.setState({ isHistoryFirstLoad: true });

    const historyFirstVersionId = this.currentProjectHistory().firstVersionId;
    if (historyFirstVersionId) {
      this.getDataWithParams(historyFirstVersionId).then(data => {
        this.setState({ historyFirstBlocks: data });
      });
      workLinkLoader(historyFirstVersionId).then(links => this.setState({ historyFirstLinks: links }));
    } else {
      this.setState({ historyFirstBlocks: [] });
      this.setState({ historyFirstLinks: [] });
    }
  }

  getHistorySecondDataFromServer = () => {
    this.setState({ isHistorySecondLoad: true });

    const historySecondVersionId = this.currentProjectHistory().secondVersionId;
    if (historySecondVersionId) {
      this.getDataWithParams(historySecondVersionId).then(data => {
        this.setState({ historySecondBlocks: data });
      });
      workLinkLoader(historySecondVersionId).then(links => this.setState({ historySecondLinks: links }));
    } else {
      this.setState({ historySecondBlocks: [] });
      this.setState({ historySecondLinks: [] });
    }
  }

  isStatusActive = () => {
    return getDictByCode(this.props.dict.status, ProjectStatus.RELEASE)?.id === this.props.projectData.statusId;
  };

  isShowEdit = () => {
    if (this.isStatusActive() && !this.props.checkRight(UserRight.WORK_EDIT_IN_RELEASE)) {
      return false;
    }

    return true;
  };

  downloadXls = () => {
    return getTableXls(this.props.projectId, {
      ...this.state.filterState,
      withLinked: false,
    });
  };

  downloadMilestoneXls = () => {
    if (this.props.projectId) {
      return getMilestoneXls(this.props.projectId, this.state.milestoneFilterState);
    }
  };

  initMsProjectData = async (projectData, works, linkList, workStatusDict, workLinkTypeDict) => {
    setAutoFakeIndex(minBy<Work>(works, 'id')?.id || 0);
    const [groupedWorks] = prepareWorksForGantt(works, projectData.projectId, workStatusDict, item => ({
      projectId: projectData.projectId,
    }));

    const links = linkList.map(item => ({
      ...item,
      type: getDictObj(workLinkTypeDict, item.typeId).code,
    }));
    return {work: groupedWorks, link: links};
  };

  onSaveMsProject = async (data) => {
    const fixData = (data, startSort) => {
      return {
        projectId: this.props.projectData.projectId,
        projectVersionId: this.props.projectData.id,
        link: uniqBy<GanttTableLink>(data.link, getLinkKey),
        work: flatten(data.work).map((work, index) => {
          return prepareWorkSave({
            ...work,
            projectVersionId: work.projectVersionId ? work.projectVersionId : this.props.projectData.id,
            sort: startSort + index + 1
          });
        }).filter(item => !item.isFromOtherProject),
      };
    };

    const maxSort = await service.get(`/work/maxSort/project/${this.props.projectData.id}`);

    await service.put('/work/gantt/save', fixData(data, maxSort), { isShowLoad: true });

    this.getBlockFromServer();
  };

  generateFromTemplate = () => {
    return generateFromTemplate(this.props.projectId)
      .then(works => {
        this.initMsProjectData(this.props.projectData, works.work, works.link,
          this.props.dict.workStatus, this.props.dict.workLinkType)
          .then(initiated => this.onSaveMsProject(initiated));
      });
  };

  getProjectBase = () => i18n.getDataByLanguage(this.props.projectData.projectTypeCode)?.translation['base'];

  onToggleWork = (workId) => {
    this.setState(({ blocks }) => ({
      blocks: blocks.map(item => ({
        ...item,
        isChecked: item.id === workId ? !item.isChecked : item.isChecked
      }))
    }));
  };

  isShowRemove = () => this.isShowEdit() && this.props.projectData.edit && !isEmpty(this.state.blocks);

  onToggleAll = () => {
    const isRemoveAvail = (work: Work) => ((this.isShowRemove && !work.isExtreme) || work.projectId !== this.props.projectData.projectId);

    this.setState(({ blocks }) => {
      const isChecked = !blocks
        .filter(isRemoveAvail)
        .every(({ isChecked }) => isChecked);

      return {
        blocks: blocks.map(item => ({
          ...item,
          isChecked: isRemoveAvail(item) ? isChecked : false,
        }))
      };
    });
  };

  getIsCheckedWork = () => this.state.blocks.some(({ isChecked }) => isChecked);

  removeWorks = () => {
    const actions = [];

    const worksRemoveId = this.state.blocks
      .filter(({ projectId, isChecked }) => isChecked && projectId === this.props.projectData.projectId)
      .map(({ id }) => id);
    if (!isEmpty(worksRemoveId)) {
      actions.push(removeWorkList(worksRemoveId));
    }

    const worksUnlinkId = this.state.blocks
      .filter(({ projectId, isChecked }) => isChecked && projectId !== this.props.projectData.projectId)
      .map(({ id, workId }) => ({ staticWorkId: workId || id }));
    if (!isEmpty(worksUnlinkId)) {
      actions.push(unlinkWorkList(this.props.projectData.projectId, worksUnlinkId));
    }

    return Promise.all(actions).then(() => {
      this.getBlockFromServer();
      this.props.updateRequiredSections(this.props.projectData?.projectVersionId);
    });
  };

  render() {
    const {
      blocks, links, filterState, viewType, milestoneFilterState, milestones,
      historyFirstBlocks, historyFirstLinks, historySecondBlocks, historySecondLinks } = this.state;
    const { history, dict, projectId, projectData, checkRight } = this.props;
    const projectBase = this.getProjectBase();
    const isCheckedWork = this.getIsCheckedWork();
    const projectStatus = getDictCodeById(dict.status, projectData.statusId) as ProjectStatus;
    const isAddFromOtherProjectAvail = ![ProjectStatus.ARCHIVE, ProjectStatus.COORDINATION].includes(projectStatus);
    const isShowRemove = this.isShowRemove();
    const projectHistory = this.currentProjectHistory();
    const isShowGantt = [ViewType.LIST, ViewType.TABLE].includes(viewType)
      && ((!projectHistory.isEnabled && !isEmpty(blocks))
        || (projectHistory.isEnabled && (!isEmpty(historyFirstBlocks) || !isEmpty(historySecondBlocks))));

    return (
      <ExtendableWrapper {...this.state.extModule} props={{ state: this.state, props: this.props }}>
        <div className="row work-list">

          <div className="col-12">
            <HistoryBlock
              projectId={projectData.projectId}
              sectionCode={ProjectSection.WORKS}
              isHide={viewType !== ViewType.TABLE}
            />
          </div>

          {viewType !== ViewType.MILESTONE &&
              <div className="col-12">
                  <FilterWorks
                      value={filterState}
                      onChange={filterState => {
                        this.setState({ filterState });
                        this.props.dispatchToLocal(getFilterKey(), filterState);
                      }}
                      works={blocks}
                  />
              </div>
          }

          {viewType === ViewType.MILESTONE &&
              <div className="col-12">
                  <FilterMilestone
                      defaultFilter={milestoneDefaultFilter}
                      value={milestoneFilterState}
                      onChange={milestoneFilterState => {
                        this.setState({ milestoneFilterState });
                        this.props.dispatchToLocal(`${getFilterKey()}-milestone`, milestoneFilterState);
                      }}
                      milestones={milestones}
                  />
              </div>
          }

          <div className="col-sm-12">
            <div className="work-switch__container">
              <TabButtons
                className="mb-3"
                data={[
                  { title: 'Список', value: ViewType.LIST, isDisabled: projectHistory.isEnabled },
                  { title: 'Таблица', value: ViewType.TABLE },
                  { title: 'Диаграмма Ганта', value: ViewType.GANTT, isDisabled: projectHistory.isEnabled },
                  { title: 'Контрольные точки', value: ViewType.MILESTONE, isDisabled: projectHistory.isEnabled },
                ]}
                value={viewType}
                onChange={(viewType) => {
                  this.props.dispatchToLocal(`card-${this.props.projectData?.id}-viewType`, viewType);
                  this.setState({ viewType });
                }}
              />
              <button className="link active excel-export mb-3" onClick={viewType === ViewType.MILESTONE
                ? this.downloadMilestoneXls
                : this.downloadXls}>Выгрузить в Excel</button>
              {projectData.edit && viewType !== ViewType.MILESTONE && <ButtonRtAccept
                className="link active excel-export mb-3 marginLeft-12 border-0"
                children="Загрузить пример плана-графика"
                onClick={this.generateFromTemplate}
                message='Вы действительно хотите загрузить пример плана-графика? Ваш план график будет дополнен примером из шаблона внизу текущего плана графика'
              />}
            </div>
          </div>
          {viewType === ViewType.LIST && (
            <div className="col-12">
              <SpoilerResultWorks
                data={blocks}
                projectId={projectId}
                dict={dict}
                projectData={projectData}
                isStatusActive={this.isStatusActive}
                isShowEdit={this.isShowEdit}
                editButton={this.editButton}
                getJiraUrl={this.getJiraUrl}
                projectBase={projectBase}
                onToggleWork={this.onToggleWork}
                isShowRemove={isShowRemove}
              />
            </div>
          )}

          {viewType === ViewType.TABLE && (
            <div className="col-12 mb-3">
              <TableResultWorks
                dataFirst={projectHistory.isEnabled ? historyFirstBlocks : blocks}
                dataSecond={historySecondBlocks}
                dict={dict}
                getJiraUrl={this.getJiraUrl}
                projectData={projectData}
                order={{
                  order: filterState.sortOrder,
                  dataField: filterState.sortDataField,
                }}
                setOrder={(sortDataField, sortOrder) => this.setState(({ filterState }) => ({
                  filterState: {
                    ...filterState,
                    sortDataField,
                    sortOrder,
                  }
                }))}
                onToggleWork={this.onToggleWork}
                onToggleAll={this.onToggleAll}
                isShowRemove={isShowRemove}
              />
            </div>
          )}

          {viewType === ViewType.GANTT && (
            <GanttWithLoad
              projectData={projectData}
              works={blocks}
              links={links}
              filter={filterState}
              readonly
            />
          )}

          {viewType === ViewType.MILESTONE &&
              <Milestone projectVersionId={projectId}
                         filterState={milestoneFilterState}
                         setMilestoneList={milestones => this.setState({milestones: milestones})}/>
          }

          <div className="col-12">
            <div className="project-append-button__container">
              {checkRight(UserRight.EDIT_PROJECT) && (
                <>
                  {projectData.edit && ![ViewType.GANTT, ViewType.MILESTONE].includes(viewType) && (
                    <ButtonAppend
                      title="Добавить работу"
                      onClick={() => history(`/${projectBase}/${projectId}/work/add`)}
                    />
                  )}

                  {projectData.edit && ![ViewType.GANTT, ViewType.MILESTONE].includes(viewType) && (
                    <ButtonAppend
                      title="Добавить контрольную точку"
                      onClick={() => history(`/${projectBase}/${projectId}/work/milestone/add`)}
                    />
                  )}

                  {isAddFromOtherProjectAvail && (
                    <AddFromOtherProjects
                      projectId={projectData.projectId}
                      projectVersionId={projectData.id}
                      afterAdd={this.getBlockFromServer}
                    />
                  )}

                  {projectData.edit && (
                    <Link
                      to={`/${projectBase}/${projectId}/work/copy`}
                      className="mb-3 form-group copy-work__button button-outline-add-felix"
                    >
                      Импорт графика работ
                    </Link>
                  )}

                  {projectData.edit && checkRight(UserRight.IMPORT_WORKS_MS_PROJECT) && (
                    <Link
                      to={`/${projectBase}/${projectId}/work/msproject`}
                      className="mb-3 form-group copy-work__button button-outline-add-felix"
                    >
                      Импорт из MS Project
                    </Link>
                  )}

                  {/* на ганте пока групповое удаление блоков работ не поддерживается  */}
                  {isCheckedWork && !projectHistory.isEnabled && viewType !== ViewType.GANTT && (
                    <AcceptDelete
                      onOk={() => this.removeWorks()}
                      className="mb-3 form-group button-outline-add-felix red"
                    >
                      Удалить
                    </AcceptDelete>
                  )}
                </>
              )}
            </div>
          </div>

          {isShowGantt ? (
            <div className="col-12">
              <Gantt
                projectId={projectData.projectId}
                works={projectHistory.isEnabled ? historyFirstBlocks : blocks}
                secondaryWorks={projectHistory.isEnabled ? historySecondBlocks : []}
                links={projectHistory.isEnabled ? historyFirstLinks : links}
                secondaryLinks={projectHistory.isEnabled ? historySecondLinks : []}
                isShowDateEndInit={projectHistory.isShowGanttDateEndInit}
              />
            </div>
          ) : null}
        </div>
      </ExtendableWrapper>
    );
  }
}

const mapStateToProp = (state, props) => ({
  dict: state.dict,
  checkRight: checkRight(state, props.projectData),
  checkEdit: checkEdit(state, props.projectData),
  projectHistory: state.ProjectHistory,
  localStorage: state.UserStorage.local,
});

const mapDispatchToProps = (dispatch) => ({
  updateRequiredSections: (projectVersionId) => dispatch(updateRequiredSections(projectVersionId)),
  dispatchToLocal: (key, state) => dispatch(addUserValue(key, state))
});

export default connect(mapStateToProp, mapDispatchToProps)(withDeviceQuery(WorkList));
