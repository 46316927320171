import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import Select from '@/elements/Select';
import ButtonRt from '@/components/Helper/ButtonRt';
import { checkGroup } from '@/pages/CreateProject/Blocks/utils';
import SwitchSliderTitle from '@/elements/SwitchSliderTitle';
import { isEqual } from 'lodash';
import { insertBeforeId, isChild, removeById, searchIndex } from '@/components/Gantt/util/utils';
import { fixGroup } from '@/components/Gantt/util/linkUtil';
import { useWeekendUtil } from '@/components/Gantt/util/dateUtil';
import { useAppSelector } from '@/store';

const TOP_LIST_INDEX = -99999;

const MoveRowModal = ({ moveRowData, setMoveRowData, rows, setData, expanded }) => {
  const weekendUtil = useWeekendUtil();
  const workStatusDict = useAppSelector(state => state.dict.workStatus);
  const [selectedId, setSelectedId] = useState(null);
  const [isMoveToGroup, setIsMoveToGroup] = useState(false);

  const currentItem = moveRowData.row?.original;
  const selectedRow = rows.find(row => row.id === selectedId);
  const selectedItem = selectedRow?.original;
  const isSelectedGroup = selectedRow && checkGroup(selectedItem);

  const optionsForSelect = () => {
    const options = rows
      .filter(row => row.id !== moveRowData.row?.id && !isChild(moveRowData.row?.subRows, row.id))
      .filter(row => {
        const isBothFromCurProject = !currentItem?.isFromOtherProject && !row.original?.isFromOtherProject;
        return isBothFromCurProject || currentItem?.workGroupId === row.original?.workGroupId
      })
      .map((row) => ({label: `${row.original.rowNum} - ${row.original.name}`, value: row.id}));

    if (moveRowData.row?.original?.rowNum !== '1') {
      options.unshift({ label: 'Перенести в начало списка', value: TOP_LIST_INDEX });
    }

    return options;
  }

  const isSameParent = selectedRow
    && isEqual(selectedRow.original.rowNum.split('.').slice(0, -1), moveRowData.row?.original.rowNum.split('.').slice(0, -1));

  const currentDragIndex = () => moveRowData.row ? rows.findIndex(row => row.id === moveRowData.row.id) : null;
  const currentHoverIndex = () => selectedRow ? rows.findIndex(row => row.id === selectedRow.id) : TOP_LIST_INDEX;

  const newPosition = () => {
    if (!selectedRow || !selectedRow.original) {
      return '1';
    }

    const positionArray = selectedRow.original.rowNum.split('.');
    if (isSelectedGroup && isMoveToGroup) {
      positionArray.push('1');
    } else {
      const dragIndex = currentDragIndex();
      const hoverIndex = currentHoverIndex();

      positionArray[positionArray.length - 1] = +positionArray[positionArray.length - 1]
        + (isSameParent && (dragIndex < hoverIndex) ? 0 : 1);
    }
    return positionArray.join('.');
  }

  const onOk = () => {
    const dragIndex = currentDragIndex();
    const hoverIndex = currentHoverIndex();

    setData(oldData => {
      const data = update(oldData, dragIndex, hoverIndex);
      if (data === false) {
        return oldData;
      }
      return data;
    });

    onClose();
  }

  const onClose = () => {
    setSelectedId(null);
    setIsMoveToGroup(false);
    setMoveRowData({ isShowModal: false, row: null });
  }

  const update = (arr, dragIndex, hoverIndex) => {
    const itemDrag = searchIndex(arr, dragIndex, expanded);
    const itemHover = searchIndex(arr, hoverIndex === TOP_LIST_INDEX ? 0 : hoverIndex, expanded);
    const isMoveIntoGroup = isSelectedGroup && isMoveToGroup;

    const isBothFromCurProject = !itemDrag.isFromOtherProject && !itemHover.isFromOtherProject;

    if (isChild(itemDrag.subRows, itemHover.id)) {
      return false;
    }

    let result = arr;

    if (!isBothFromCurProject && (itemDrag.workGroupId !== itemHover.workGroupId)) {
      return false;
    }

    result = removeById(result, itemDrag.id);
    result = insertBeforeId(
      result,
      itemHover.id,
      dragIndex > hoverIndex ? hoverIndex === TOP_LIST_INDEX : false,
      itemDrag,
      isMoveIntoGroup,
      isMoveIntoGroup
    );

    result = fixGroup(result, weekendUtil, workStatusDict);

    return result;
  }

  const isBothFromCurProject = !currentItem?.isFromOtherProject && !selectedItem?.isFromOtherProject;
  const isMoveToGroupDisabled = !isBothFromCurProject && ((currentItem?.workGroupId !== selectedItem?.workGroupId) || checkGroup(selectedItem));

  return moveRowData.isShowModal && (
    <Modal
      onHide={onClose}
      show={moveRowData.isShowModal}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      size="lg"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="h3">Перемещение блока работ</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-2'>
          <span>Блок работ: {moveRowData.row?.original?.name}</span><br/>
          <span>Текущая позиция: {moveRowData.row?.original?.rowNum}</span><br/>
          <span>Новая позиция: {newPosition()}</span><br/>
        </div>
        <div>
          Выберите блок работ, после которого необходимо разместить перемещаемый блок работ
          <Select
              options={optionsForSelect()}
              label="Блок работ"
              value={selectedId}
              isClearable={true}
              onChange={setSelectedId}
              isPortal
          />
        </div>
        {isSelectedGroup && !isMoveToGroupDisabled && (
          <SwitchSliderTitle
            checked={isMoveToGroup}
            onChange={setIsMoveToGroup}
            title='Переместить в группу'
            mini
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className='button-felix-group'>
          <ButtonRt onClick={onOk} disabled={selectedId == null}>
            Переместить
          </ButtonRt>
          <ButtonRt type="outline" onClick={onClose}>
            Отмена
          </ButtonRt>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default MoveRowModal;