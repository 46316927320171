import { compact } from 'lodash';
import React from "react";
import { CalendarType, CalendarTypeData, LinkTypesData, SAUSAGE_HEIGHT } from '@/components/Gantt/const';
import GanttLineItem from '@/components/Gantt/elements/GanttLineItem';
import { getDictCodeById } from '@/utils';
import { useAppSelector } from '@/store';

const fixCoords = (item: PositionGanttItemType, isStart: boolean) => {
  return {
    x: item.x + (isStart ? 0 : item.width),
    y: item.y + SAUSAGE_HEIGHT * 0.5
  };
};

interface GanttLinkProps {
  data: GanttTableItem[];
  links: GanttTableLink[];
  positions: PositionGanttItemsType;
  nowPosition: number;
  dateRange: DateRangeType;
  calendarType: CalendarType;
}

const GanttLink = ({ data, links, positions, nowPosition, dateRange, calendarType }: GanttLinkProps) => {
  const linkTypeDict = useAppSelector(state => state.dict.workLinkType);

  const headerWidth = CalendarTypeData[calendarType].headerWidth;
  const momentType = CalendarTypeData[calendarType].momentType;
  const layout = [];
  const startClone = dateRange.min.clone();
  let width = 0;
  while (startClone.isSameOrBefore(dateRange.max)) {
    const curWidth = headerWidth(startClone);
    width += curWidth;
    layout.push({
      x1: width,
      y1: 0,
      x2: width,
      y2: data.length * SAUSAGE_HEIGHT,
    });
    startClone.add(1, momentType);
  }
  for (let i = 1; i <= data.length; i++) {
    layout.push({
      x1: 0,
      y1: i * SAUSAGE_HEIGHT,
      x2: width,
      y2: i * SAUSAGE_HEIGHT,
    });
  }

  const nowLine = {
      x1: nowPosition,
      x2: nowPosition,
      y1: 0,
      y2: data.length * SAUSAGE_HEIGHT,
  };

  const lines = compact(links.map(link => {
    const from = data.find(item => item.id === link.fromId);
    const to = data.find(item => item.id === link.toId);

    if (!from || !to || !positions[from.id]?.work || !positions[to.id]?.work) {
      return undefined;
    }

    const typeCode = getDictCodeById(linkTypeDict, link.typeId);
    const type = LinkTypesData[typeCode];

    return {
      from: fixCoords(positions[from.id].work, type.from),
      to: fixCoords(positions[to.id].work, type.to),
      type,
      cpm: from.cpm && to.cpm,
    };
  }));

  return (
    <div className="gantt-link__container" style={{
      height: data.length * SAUSAGE_HEIGHT,
      width
    }}>
      <svg className="gantt-link">
        {layout.map((item, i) => (
          <line key={`layout-${i}`} {...item} stroke='#e7e7e7' strokeWidth={1} />
        ))}
        <line key={`now-line`} {...nowLine} stroke='red' strokeWidth={1}/>
        {lines.map((line, i) => (
          <GanttLineItem key={i} from={line.from} to={line.to} type={line.type} cpm={line.cpm} />
        ))}
      </svg>
    </div>
  );
};

export default GanttLink;