import { felixDict } from '@/store/dict';

export const START_LOAD_DICT = "START_LOAD_DICT";
export const UPDATE_DICT = "UPDATE_DICT";

export const STATUS_NO_LOAD = "STATUS_NO_LOAD"
export const STATUS_LOAD = "STATUS_LOAD"
export const STATUS_LOADING = "STATUS_LOADING"

const initialState = () => felixDict.reduce((acc, key) => ({
  ...acc,
  [key]: {
    status: STATUS_NO_LOAD,
    isLoad: false,
    data: [],
  }
}), {}) as Dict;

// fixme: any
const Dictionaries = (state: Dict = initialState(), action) => {
  switch (action.type) {
    case START_LOAD_DICT:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          status: STATUS_LOADING,
          isLoad: false,
        }
      }
    case UPDATE_DICT:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          status: STATUS_LOAD,
          isLoad: true,
          data: action.data
        }
      }

    default:
      return state;
  }
}

export default Dictionaries;
