import i18n from 'i18next';
import { get, isEmpty, keyBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getBasicNewProjectData } from '@/actions/getBasicNewProjectData';
import { modalShow } from '@/actions/modal';
import ModalAsync from '@/components/ModalAsync';
import ProjectHeader from '@/components/ProjectHeader';
import HeaderButtons from '@/pages/CreateProject/Basic/HeaderButtons';
import EditBlockView from '@/pages/CreateProject/Blocks/Components/EditBlockView';
import { isEmptyNotResponsible, NO_TEAM_KEY } from '@/pages/CreateProject/Blocks/Components/ResponsibleForm';
import { checkMilestone, checkTask, workGroupSort } from '@/pages/CreateProject/Blocks/utils';
import services from '@/services';
import service from '@/services';
import {
  checkEdit,
  checkRight,
  getDictByCode,
  getDictByName, getDictObj, isEmptyValues } from '@/utils';
import { withRouterParams } from '@/utils/router';
import { workDataLoader } from '@/pages/CreateProject/Blocks/WorkList';

export const MAX_WORK_DURATION = 32;
export const WORK_NEED_DOC_MESSAGE = 'Внимание. Требуется приложить подтверждающие документы или указать ссылку на информацию о выполнении блока работ в другой системе для изменения данных по задаче';

const skupDefault = {
  dateStart: {
    from: null,
    to: null
  },
  dateEndPlan: {
    from: null,
    to: null
  },
  dateEndFact: {
    from: null,
    to: null
  },
};

const progressDefault = {
  nameNaturalIndex: null,
  plannedValue: null,
  factValue: null,
};

const post = (projectId, data) => services.post(`/work/project/${projectId}`, data);
const put = (workId, data) => services.put(`/work/${workId}`, data);
const postShadow = (projectId, data) => services.post(`/work/project/shadow/${projectId}`, data);
const putShadow = (workId, data) => services.put(`/work/shadow/${workId}`, data);

const getGroupedTeams = (data) => {
  const teams = {};
  teams[NO_TEAM_KEY] = [];
  data && data.forEach(item => {
    if (!item?.workTeamId) {
      teams[NO_TEAM_KEY].push(item);
    } else if (Object.keys(teams).includes(`${item.workTeamId}`)) {
      teams[`${item.workTeamId}`].push(item);
    } else {
      teams[`${item.workTeamId}`] = [];
      teams[`${item.workTeamId}`].push(item);
    }
  });
  return teams;
}


export const prepareWork = (data, isInitResponsibleList = true) => {
  let newData = {...data}

  if (isInitResponsibleList) {
    const groupedTeams = getGroupedTeams(data?.responsibleList);
    newData = {
      ...newData,
      isResponsibleListInit: true,
      responsibleList: groupedTeams || [],
      responsibleListMain: groupedTeams[NO_TEAM_KEY] || [],
    }
  }

  return {
    ...newData,
    skup: data.skup || skupDefault,
  };
};

export const prepareWorkSave = (data) => {
  const flattenResponsibleList = [];
  Object.keys(data.responsibleList ?? {}).filter(key => key !== NO_TEAM_KEY).forEach(key => {
    flattenResponsibleList.push(data?.responsibleList[key]);
  });

  return {
    ...data,
    responsible: data.responsible ? data.responsible.value : null,
    userUpdate: null, //Вообще не отправляем, все равно обновляется на беке
    skup: data.skup?.projectId ? data.skup : null,
    responsibleList: checkTask(data) && data.isResponsibleListInit
      ? [...flattenResponsibleList?.flat().filter(isEmptyNotResponsible),
        ...data.responsibleListMain?.filter(isEmptyNotResponsible)]
      : null,
  };
};

export const appendResponsibleFact = async (data, isFromGantt = false) => {
  if (!data || isEmptyValues(data.workId) || data.isResponsibleFactInit) {
    return data;
  }

  if (isFromGantt && !data.isResponsibleListInit) {
    data.responsibleList = await service.get(`/work/getResponsibleList/${data.workId}`);
    data = prepareWork(data);
    data.isResponsibleListInit = true;
  }

  data.isResponsibleFactInit = true;

  if (isEmptyValues(data.responsibleList)) {
    return data;
  }

  const responsibleFact = keyBy(await service.get('/timesheet/getFact', { staticWorkId: data.workId }), 'workResponsibleId');

  if (isFromGantt) {
    Object.keys(data.responsibleList)
      .forEach(team => data.responsibleList[team]
        .forEach(item => item.workFact = responsibleFact[item.id]?.val));
  } else {
    data.responsibleList.forEach(item => item.workFact = responsibleFact[item.id]?.val);
  }
  return data;
};

class EditBlock extends React.Component<any, {
  data: Work;
  links: GanttTableLink[];
  shadowSaveResult: {
    isEdit: boolean;
    item: any;
  };
  [key: string]: any;
}> {
  docs: any;
  modalRefNeedCreate: any;
  editBlockViewFuncRef: any;
  constructor(props) {
    super(props);

    this.state = {
      data: ({
        typeId: 1,
        projectId: props.newProjectData.projectId,
        skup: skupDefault,
        ...progressDefault,
        responsibleListMain: []
      } as Work),
      getAllwork: [],
      initStatusId: null,
      capitalizationYearFilter: -1,
      capitalizationTableHash: Math.random(),
      predictionList: [],
      links: [],
      shadowSaveResult: {
        isEdit: false,
        item: undefined
      }
    };

    this.docs = React.createRef();
    this.modalRefNeedCreate = React.createRef();
    this.editBlockViewFuncRef = React.createRef();
  }

  componentDidMount() {
    const { projectVersionId } = this.props;

    if (this.props.isEdit) {
      this.getData();
    }

    workDataLoader(projectVersionId, { params: JSON.stringify({ withLinked: true }) })
      .then((body) => {
        this.setState({ getAllwork: workGroupSort(body) });
      });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isEdit && this.props.isEdit) {
      this.getData();
    }
  }

  getDataFromServer = () =>
    this.props.getNewProjectData(this.props.projectVersionId);

  getWork = (workId) => services.get(`/work/${workId}`);

  getLinks = (workId) => services.get(`/work/link/${workId}`);

  getData = async () => {
    const { item } = this.props;

    let data = await this.getWork(item);
    data = await appendResponsibleFact(data);

    this.setState({
      data: prepareWork(data),
      initStatusId: data.statusId
    });

    this.getDataFromServer();
    this.loadLinks();
  };

  loadLinks = () => {
    const { item } = this.props;

    this.getLinks(item).then(links => this.setState({ links }));
  }

  getWorkStatus = (work) => {
    return get(getDictObj(this.props.dict.workStatus, work.statusId), 'code');
  };

  save = async () => {
    await this.updateLoadBlock();
    await this.getDataFromServer();
  };

  shadowSave = () => {
    const isEdit = this.state.shadowSaveResult.isEdit || this.props.isEdit;
    const projectVersionId = this.props.projectVersionId;
    const item = this.state.shadowSaveResult?.item ?? this.props.item;

    const data = prepareWorkSave(this.state.data);
    data.links = this.state.links;

    if (isEdit) {
      return putShadow(item, data)
        .then(() => this.docs.current?.sendFiles(projectVersionId, data.workId))
        .then((result) => this.setState({shadowSaveResult: {isEdit: true, item: result?.id}}));
    }

    return postShadow(projectVersionId, data)
      .then((result) => this.setState({shadowSaveResult: {isEdit: !!result?.id, item: result?.id}}));
  }

  afterSave = async () => {
    this.props.history(this.props.url, {state: {disableBlock: true}});
  };

  updateLoadBlock = async () => {
    const isEdit = this.state.shadowSaveResult.isEdit || this.props.isEdit;
    const projectVersionId = this.props.projectVersionId;
    const item = this.state.shadowSaveResult.item ?? this.props.item;

    const data = prepareWorkSave(this.state.data);
    data.links = this.state.links;

    if (!this.isStatusDraft()
      && ['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(this.getWorkStatus(data))
      && data.statusId !== this.state.initStatusId
      && this.docs.current?.isEmpty()
      && isEmpty(data.urlList.filter(url => !url.isRemoved))
    ) {
      if (!checkMilestone(data) || !this.props.newProjectData?.isSkipCreateSystemMilestoneAndCheck) {
        this.props.showModal({
          message: WORK_NEED_DOC_MESSAGE,
          title: 'Внимание'
        });
        throw WORK_NEED_DOC_MESSAGE;
      }
    }

    if (isEdit) {
      return put(item, data).then(() => this.docs.current?.sendFiles(projectVersionId, data.workId));
    }

    return post(projectVersionId, data);
  };

  cancel = () => {
    this.props.history(this.props.url, {state: {disableBlock: true}});
  };

  deleteTask = () => {
    const { history, item } = this.props;
    return services.remove(`/work/${item}`).then(() => {
      history(this.props.url);
    });
  };

  isStatusDraft = () => {
    return getDictByCode(this.props.dict.status, 'DRAFT')?.id === this.props.newProjectData.statusId;
  };

  isPredictionCoordinate = () => {
    return getDictByCode(this.props.dict.budgetPredictionStatus, 'COORDINATION')?.id === this.props.newProjectData.budgetPredictionStatusId;
  };

  isEditBudgetPrediction = () => {
    return this.props.checkRight("EDIT_BUDGET_PREDICTION") && !this.isPredictionCoordinate();
  };

  setData = (func) => {
    this.setState((oldState) => ({
      data: func(oldState.data)
    }));
  };

  render() {
    const { isEdit, projectVersionId } = this.props;

    const isEditBudgetPrediction = this.isEditBudgetPrediction();

    return (
      <React.Fragment>
        <HeaderButtons backTo={this.props.url} />
        <div className="wrapper-option">
          <ProjectHeader title={`${isEdit ? 'Редактировать' : 'Добавить'} работу`} />
        </div>

        <EditBlockView
          data={this.state.data}
          setData={this.setData}
          links={this.state.links}
          setLinks={updateLinks => this.setState({ links: updateLinks(this.state.links) })}
          isEdit={isEdit}
          projectVersionId={projectVersionId}
          docs={this.docs}
          onCancel={this.cancel}
          onDeleteTask={this.deleteTask}
          onSave={this.save}
          shadowSave={this.shadowSave}
          afterSave={this.afterSave}
          isEditBudgetPredictionRight={isEditBudgetPrediction}
          getAllWork={this.state.getAllwork}
          funcRef={this.editBlockViewFuncRef}
        >

        </EditBlockView>

        <ModalAsync
          title="Внимание"
          ref={this.modalRefNeedCreate}
          okTitle="Сохранить"
          cancelTitle="Отменить"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProp = (state) => ({
  newProjectData: state.NewProject.newProjectData,
  dict: state.dict,
  checkRight: checkRight(state),
  isProjectEdit: checkEdit(state),
});

const mapDispatchToProps = (dispatch) => ({
  getNewProjectData: (id, cb) => dispatch(getBasicNewProjectData(id, cb)),
  showModal: (message) => dispatch(modalShow(message)),
});

export default withRouterParams(connect(mapStateToProp, mapDispatchToProps)(EditBlock));
