import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Reducers from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

export default function configureStore() {
  const store = createStore(
    createReducer(),
    composeWithDevTools(applyMiddleware(thunk)),
  );

  store['injectReducer'] = (asyncReducers) => {
    store.replaceReducer(createReducer(asyncReducers));
  }

  return store;
}

function createReducer(asyncReducers = {}) {
  return combineReducers({
    ...Reducers,
    ...asyncReducers
  })
}
