import React, { Fragment, useRef } from "react";
import { useAppSelector } from '@/store';
import { getDictObj } from "@/utils";
import { StatusItem } from "@/components/Status";
import cx from "classnames";
import { useCheckRight, useCurrentUser } from "@/utils/hooks";
import ButtonRt from "@/components/Helper/ButtonRt";
import service from "@/services";
import SendToReworkBti from "@/pages/CreateProject/Status/SendToReworkBti";
import ModalAsync from "@/components/ModalAsync";

const agree = (statusId) => service.put(`/status-report/agree/${statusId}`);

const ReportStatusBti = ({statusReport, goBack = undefined, sendToAgree = undefined,  pullStatus = undefined, isEdit = false}
  : {statusReport: any, goBack?: any, sendToAgree?: any, pullStatus?: any, isEdit?: boolean}) => {
  const dictReportAgreeStatus = useAppSelector(state => state.dict.reportAgreeStatus);
  const agreeStatus = getDictObj(dictReportAgreeStatus, statusReport.agreeStatusId);
  const user = useCurrentUser();
  const checkRight = useCheckRight();
  const modalRef = useRef(null);

  const onAgree = async () => {
    try {
      await agree(statusReport.id);
      pullStatus && await pullStatus(statusReport.id);
      goBack && goBack();
    } catch (e) {
      pullStatus && await pullStatus(statusReport.id);
      goBack && goBack();
    }
  };

  return (
    <Fragment>
      {agreeStatus?.code !== 'DRAFT'
        && <StatusItem className={cx('marginBottom-10 status big-font', agreeStatus?.code)}
                       onClick={['COORDINATION', 'RELEASE'].includes(agreeStatus?.code)
                         ? () => {modalRef.current?.open()}
                         : undefined}
                       style={['COORDINATION', 'RELEASE'].includes(agreeStatus?.code) ? {cursor: 'pointer'} : {}}
                       text={agreeStatus?.name}/>}
      {agreeStatus?.code === 'COORDINATION'
        && isEdit
        && statusReport?.agreeCandidateIds?.includes(user?.id)
        && <div>
          <ButtonRt onClick={onAgree}>Согласовать и опубликовать</ButtonRt>
          <SendToReworkBti goBack={goBack} statusReport={statusReport} pullStatus={pullStatus}/>
        </div>
      }
      {agreeStatus?.code === 'REWORK'
        && isEdit
        && <div className={'marginBottom-10'}>{statusReport?.reworkComment}</div>}
      {agreeStatus?.code === 'REWORK'
        && isEdit
        && checkRight('REPORT_UPDATE')
        && <div>
          <ButtonRt onClick={() => sendToAgree && sendToAgree()}>Опубликовать</ButtonRt>
      </div>}
      {agreeStatus?.code === 'COORDINATION' && <ModalAsync title="На согласовании" ref={modalRef} isAlert={true}>
          Статус-отчет находится на согласовании у пользователей:
        {statusReport?.agreeCandidates?.map(user => {
          return <div>- {user.displayName}</div>
        })}
      </ModalAsync>}
      {agreeStatus?.code === 'RELEASE' && <ModalAsync title="Согласовано" ref={modalRef} isAlert={true}>
          Статус-отчет согласован пользователем:
          <div>- {statusReport?.agreeUser?.displayName}</div>
      </ModalAsync>}
    </Fragment>
  );
}

export default ReportStatusBti;