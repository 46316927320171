import React from "react";

import Select from "@/elements/Select/Select";
import SelectInTable from "@/elements/Select/SelectInTable";
import { dictTreeToDict, getDictForSelect } from '@/utils';
import { useAppSelector } from '@/store';

const SelectDict = ({
  dictName = undefined, customOptions = [], filterFunc = undefined, isDictTree = false, ...props
}: SelectProps & { dictName?: string, customOptions?: any[] }) => {
  const dict = useAppSelector(state =>
    dictName
      ? (isDictTree
        ? getDictForSelect(dictTreeToDict(state.dict[dictName]), undefined, filterFunc)
        : getDictForSelect(state.dict[dictName], undefined, filterFunc))
      : customOptions,
  );

  const Component = props.inTable ? SelectInTable : Select;

  return (
    <Component
      {...props}
      newTable={props.inTable}
      options={dict}
    />
  );
};

export default SelectDict;