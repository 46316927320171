import './style.scss';
import cx from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend';
import { Link, useNavigate } from 'react-router-dom';
import { CalendarType, CalendarTypeData } from '@/components/Gantt/const';
import {
  AppendIcon,
  BackIcon,
  EditIcon,
  FullscreenIcon,
  PlanIcon,
  RemoveIcon,
  SaveIcon,
} from '@/components/Gantt/elements/Icons';
import useSplit from "@/components/Gantt/hooks/useSplit";
import useSyncScroll from "@/components/Gantt/hooks/useSyncScroll";
import {
  findAndUpdate,
  getAutoFakeIndex,
  getDateRangeMilestoneSimple,
  getDuration
} from '@/components/Gantt/util/utils';
import { UserRight } from '@/config/const';
import { useDeviceContext } from '@/context/DeviceContext';
import Message from '@/elements/Message';
import TabButtons from '@/pages/Dashboard2/components/TabButtons';
import { isEmptyValues, isNotEmptyValues, momentToSelectDate } from '@/utils';
import { getPathByType } from '@/utils/project';
import { cloneDeep, isEmpty } from 'lodash';
import MilestoneGanttTable from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGanttTable";
import MilestoneGanttGraph from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGanttGraph";
import MilestoneGanttEditItem from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGanttEditItem";
import service from "@/services";
import { milestoneDefaultFilter } from "@/pages/CreateProject/Blocks/Milestone/Milestone";
import DirtyFormModal from "@/elements/DirtyFormModal";
import { useDispatch } from "react-redux";
import { updateRequiredSections } from "@/actions/required/updateRequiredSections";
import moment from "moment";
import { useStateWithKey } from '@/utils/hooks';
import MultipleEditModal from '@/components/Gantt/components/MultipleEditModal';
import { useAppSelector } from '@/store';
import { useWeekendUtil } from '@/components/Gantt/util/dateUtil';

const postMilestoneGantt = (projectVersionId, data) =>
  service.post(`/milestone/updateGantt/${projectVersionId}`, data);

const getMilestoneList = (projectVersionId, filter) =>
  service.post(`/milestone/project/${projectVersionId}`, filter);

const dataTabButtons = Object.entries(CalendarTypeData)
  .map(([value, { label: title }]) => ({
    title,
    value
  }));

const getCalendarType = (days: number): CalendarType => {
  if (days > 180) {
    return CalendarType.YEAR;
  }

  if (days > 70) {
    return CalendarType.MONTH;
  }

  if (days > 30) {
    return CalendarType.WEEK;
  }

  return CalendarType.DAY;
};


const MilestoneGanttInside = ({
  projectData,
  milestone,
  readonly,
  isLoading,
  initCalendarType,
  loadData,
  projectVersionId
}: MilestoneGanttProps) => {
  const workStatusDict = useAppSelector(state => state.dict.workStatus);
  const weekendUtil = useWeekendUtil();
  const { splitPercent, dragRef, dropRef } = useSplit(0.5);
  const [calendarType, setCalendarType] = useState(initCalendarType || CalendarType.DAY);
  const [openMilestoneIndex, setOpenMilestoneIndex] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [isFullscreen, setFullscreen] = useState(false);
  const scrollFirst = useRef(null);
  const scrollSecond = useRef(null);
  const graphRef = useRef(null);
  useSyncScroll(React.useRef([scrollFirst, scrollSecond]),
    { vertical: true, horizontal: false }, [isLoading]);
  const { isMobile, isTablet, isSmallDesktop, isDesktop } = useDeviceContext();
  const [checked, setChecked] = useState<Record<number, boolean>>({});
  const isAnyChecked = Object.values(checked).includes(true);
  const navigate = useNavigate();
  const [editModalData, setEditModalDataByKey, setEditModalData] = useStateWithKey({ isShow: false, setIsShow: val => {}, isHasTask: false, isHasMilestone: false });

  const readLink = `/${getPathByType(projectData.projectTypeCode) || 'project'}/${projectData.id}/work`;
  const planLink = `${readLink}/milestone/gantt`;

  const isEditRight = projectData?.userRightCodes?.includes(UserRight.EDIT_PROJECT);

  const [isUpdate, setIsUpdate] = useState(false);
  const [localData, setLocalData]
    = useState(cloneDeep(milestone
    .map((item, index) => ({...item, rowNum: item.rowNum ?? index + 1}))));
  const dispatch = useDispatch();

  const setLocalDataWrapper = (data) => {
    setIsUpdate(true);
    setLocalData(data);
  };

  useEffect(() => {
    if (initCalendarType) {
      return;
    }

    const range = getDateRangeMilestoneSimple(milestone);
    if (range) {
      const days = getDuration(range.min, range.max);

      setCalendarType(getCalendarType(days));
    }

  }, [milestone]);

  useEffect(() => {
    loadData();
  }, [readonly]);

  useEffect(() => {
    setLocalData(cloneDeep(milestone.map((item, index) => ({...item, rowNum: index + 1}))));
  }, [milestone])

  const toggleFullscreen = () => {
    setFullscreen(old => !old);
  };

  const save = () => {
    postMilestoneGantt(projectVersionId, localData.map(item => ({...item, responsibleLogin: item.responsible?.value})))
      .then(() => getMilestoneList(projectVersionId, milestoneDefaultFilter).then(data => {
        setLocalData(cloneDeep(data.map((item, index) => ({...item, rowNum: index + 1}))));
      }))
      .then(() => navigate(readLink, {state: {disableBlock: true}}))
      .finally(() => dispatch(updateRequiredSections(projectVersionId)));
  };

  const appendLatest = useCallback(() => {
    setOpenMilestoneIndex(null);
    setLocalDataWrapper(prevState => {
      return [...prevState, {
        id: getAutoFakeIndex(),
        isLocal: true,
        projectVersionId: projectVersionId,
        rowNum: prevState.length + 1,
        name: `Без названия ${prevState.filter(item => item.name.startsWith('Без названия')).length + 1}`,
        responsible: undefined,
        datePlan: momentToSelectDate(moment()),
        datePrediction: undefined,
        dateFact: undefined,
        removed: false,
        isSystem: false
      }]
    });
  }, [setOpenMilestoneIndex]);

  const onEdit = () => {
    setEditModalData({
      isShow: true,
      setIsShow: val => setEditModalDataByKey('isShow', val),
      isHasTask: false,
      isHasMilestone: true,
    });
  }

  const editFromModal = (editData) => {
    const items = Object.entries(checked)
      .filter(([, isChecked]) => isChecked)
      .map(([key]) => localData.find(m => m.rowNum === +key).id);

    setIsUpdate(true);
    setLocalDataWrapper(oldData => findAndUpdate(oldData, items, item => ({
      ...item,
      statusId: editData.milestoneStatusId || item.statusId,
      responsible: item.isExtreme ? null : (editData.responsible || item.responsible),
      responsibleRoleId: item.isExtreme ? null : (editData.responsibleRoleId || item.responsibleRoleId),
      isLocalSaved: true,
    }), workStatusDict, weekendUtil));

    setChecked({});
  };

  const onRemove = () => {
    const checkedRecords = Object.keys(checked)
      .filter(key => checked[key])
      .map(item => parseInt(item))
      .filter(rowNum => {
        const work = localData.find(m => m.rowNum === rowNum);
        return !work.isExtreme;
      });

    setLocalDataWrapper(prevState => {
      return [...prevState.map(item => ({...item, removed: item.removed || checkedRecords.includes(item.rowNum)}))];
    });

    setChecked({});
  };

  const scrollToNow = () => {
    graphRef.current?.scrollToNow();
  }

  const getFilteredData = () => {
    const filteredData = localData.filter(item => !item.removed);

    return filteredData;
  }

  return (
    <div className={cx("gantt mb-3", { fullscreen: isFullscreen
        || !readonly, isTablet, isMobile, isSmallDesktop, isDesktop })}>
      <div className="gantt_navbar__container">
        {readonly ? (
            <div className="gantt-navbar primary mb-2">
              <Link className="gantt-navbar-item" to={planLink}>
                <PlanIcon />
                Планирование
              </Link>
            </div>
          ) : (
            <div className="gantt-navbar primary mb-2">
              <Link className="gantt-navbar-item" to={readLink}>
                <BackIcon />
                В График работ
              </Link>
            </div>
          )}

        {!readonly && (
          <>
            <div className="gantt-navbar mb-2">
              <div className="gantt-navbar-item" onClick={save}>
                <SaveIcon /> Сохранить в карточку
              </div>
              {isEditRight && projectData.edit && (
                <div className="gantt-navbar-item" onClick={appendLatest}>
                  <AppendIcon/> Добавить
                </div>
              )}
              {isEditRight && projectData.edit &&
                <div className={cx("gantt-navbar-item", {disabled: !isAnyChecked})} onClick={() => isAnyChecked && onEdit()}>
                  <EditIcon/> Изменить несколько
                </div>}
              {isEditRight && projectData.edit && (
                <div className={cx("gantt-navbar-item", {disabled: !isAnyChecked})} onClick={() => isAnyChecked && onRemove()}>
                  <RemoveIcon/> Удалить
                </div>
              )}
            </div>
          </>
        )}

        <TabButtons className="gantt mb-2" data={dataTabButtons} value={calendarType} onChange={setCalendarType} />

        <div className="gantt-navbar mb-2" style={{padding: 3}}>
          <div
            className={cx("gantt-navbar-item")}
            onClick={scrollToNow}
            style={{padding: '5px 7px'}}
          >
            Сегодня
          </div>
        </div>

        {readonly && (
          <div className="gantt-navbar mb-2">
            <div className="gantt-navbar-item" onClick={toggleFullscreen}>
              <FullscreenIcon /> {isFullscreen ? 'Выйти из полноэкранного режима' : 'Во весь экран'}
            </div>
          </div>
        )}
      </div>

      {/*<div>*/}
      {/*  <MilestoneGanttLegend />*/}
      {/*</div>*/}

      <div className="gantt-main__container">
        <div className="split__container" ref={dropRef}>
          <div className="split__item" style={{ flex: splitPercent }}>
            <div className="gantt-panel__container left custom-scrollbar">
              {isLoading ? (
                <Message text="Загрузка..." />
              ) : (
                  <MilestoneGanttTable
                    projectData={projectData}
                    data={getFilteredData()}
                    maxDepth={10}
                    setData={setLocalDataWrapper}
                    openMilestoneIndex={openMilestoneIndex}
                    setOpenMilestoneIndex={setOpenMilestoneIndex}
                    editRowId={editRowId}
                    setEditRowId={setEditRowId}
                    readonly={readonly}
                    isEditRight={isEditRight}
                    checked={checked}
                    setChecked={setChecked}
                    scrollContainer={scrollFirst}
                  />
              )}

            </div>
          </div>
          {!isLoading && (
            <div className="split_splitter" ref={dragRef}>:</div>
          )}
          <div className="split__item" style={{ flex: 1 - splitPercent }}>
            <div className="gantt-panel__container custom-scrollbar" ref={scrollSecond}>
              {isLoading && <div style={{color: 'rgba(0, 0, 0, 0)'}}>Загрузка...</div>}
              {!isLoading && (
                <MilestoneGanttGraph
                  ref={graphRef}
                  projectData={projectData}
                  scrollRef={scrollSecond}
                  data={getFilteredData()}
                  setData={setLocalDataWrapper}
                  calendarType={calendarType}
                  isEdit={!readonly && isEditRight}
                />
              )}
            </div>
          </div>
        </div>
        {isNotEmptyValues(openMilestoneIndex) && (
          <div className={cx("gantt-item-edit__container", { isMobile })} key={openMilestoneIndex}>
            <MilestoneGanttEditItem
              projectVersionId={projectVersionId}
              openMilestoneIndex={openMilestoneIndex}
              setOpenMilestoneIndex={setOpenMilestoneIndex}
              data={localData}
              updateData={setLocalDataWrapper}
              projectData={projectData}
            />
          </div>
        )}
      </div>
      <DirtyFormModal
        isDirty={isUpdate}
        preventRedirectOnSave
        onSave={async () => {await save()}} />
      <MultipleEditModal
        editModalData={editModalData}
        onSave={editFromModal}
      />
    </div>
  );
};

export interface MilestoneGanttProps {
  projectData: Project;
  milestone: Milestone[];
  projectVersionId: number;
  readonly: boolean,
  isLoading: boolean,
  loadData: any,
  initCalendarType: CalendarType,
};

const MilestoneGantt = (props: MilestoneGanttProps) => {
  const { isDesktop } = useDeviceContext();

  if (!props.projectData) {
    return null;
  }

  return (
    <DndProvider backend={isDesktop ? HTML5Backend : TouchBackend}>
      <MilestoneGanttInside
        { ...props }
      />
    </DndProvider>
  );
};

export default MilestoneGantt;