import { UPDATE_DICT } from '@/reducers/Dictionaries';
import service from '@/services';
import { getAllFelixDict } from '@/store/dict';

export const dictLoad = (...names) => {
  return (dispatchInside) =>
    service.get('/dict/all').then(data => {
      names.map(name => dispatchInside({
        type: UPDATE_DICT,
        name,
        data: data[name]
      }));
    });
};

export const loadAllDict = (dispatch) => getAllFelixDict().then(allDict => dispatch(dictLoad(...allDict)));