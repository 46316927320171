import cx from 'classnames';
import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { ItemTypes, SAUSAGE_TOP } from '@/components/Gantt/const';
import { getEmptyImage } from "react-dnd-html5-backend";

export interface MilestoneGanttItemPureProps {
  item: Milestone;
  type: string;
  lastDateType?: string;
  overdue?: boolean;
  top?: number;
  left?: number;
  width?: number;
  opacity?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  readonly?: boolean;
  dragRef?: any;
  projectData?: Project;
  closedInTime?: boolean;
  tooltip?: string;
}

export const getMilestoneImagePath = (type, overdue = false, closedInTime = false) => {
  if (type === 'system') {
    return '/images/gantt/Triangle-gray.png';
  }
  if (type === 'plan' && closedInTime) {
    return '/images/gantt/Triangle-green.png';
  }
  if (type === 'plan' && overdue) {
    return '/images/gantt/Triangle-red.png';
  }
  if (type === 'fact') {
    return '/images/gantt/Triangle-black-flip.png';
  }
  if (type === 'prediction') {
    return '/images/gantt/Triangle-pattern-flip.png';
  }
  return '/images/gantt/Triangle-black.png';
}

export const MilestoneGanttItemPure = ({
  item,
  type,
  overdue,
  top,
  left,
  width,
  opacity,
  onClick,
  projectData = undefined,
  readonly = false,
  lastDateType = undefined,
  dragRef = undefined,
  closedInTime = false,
  tooltip = undefined,
}: MilestoneGanttItemPureProps) => {

  const getDisplayName = (name) => {
    const trimmed = `${name}`.substring(0, 70).trim();
    return `${trimmed}${name.length > trimmed.length ? '...' : ''}`
  }

  const getColorClass = (type, overdue, closedInTime) => {
    if (type === 'initial') {
      return 'light-gray'
    }
    if (type === 'plan' && overdue) {
      return 'red'
    }
    if (type === 'plan' && closedInTime) {
      return 'blue'
    }
    if (type === 'fact') {
      return 'green';
    }
    return ''
  }

  const color = getColorClass(type, overdue, closedInTime);

  return (
    <div
      ref={readonly || type === 'system' || (type === 'plan' && !projectData?.edit) ? null : dragRef}
      className={cx('gantt-item__container', {
        milestone: true,
        readonly
      })}
      style={{
        top,
        width,
        left,
        opacity,
      }}
      onClick={onClick}
      title={tooltip}
    >
      <div className='gantt-item__progress' style={{width: `100%`}}></div>
      <div style={{display: 'inline-flex'}}>
        <div className={`gantt-item__title ${color}`} style={{ marginLeft: '11px' }}>{''}</div>
        {/*<img className='milestone-gantt-item__title'*/}
        {/*     draggable={false}*/}
        {/*     src={getMilestoneImagePath(type, overdue, closedInTime)}*/}
        {/*     alt={item.name}/>*/}
        {type === lastDateType && <div className='gantt-item__text'>{getDisplayName(item.name)}</div>}
      </div>
    </div>
  );
};

export interface MilestoneGanttItemProps {
  data: Milestone;
  type: string;
  lastDateType?: string;
  overdue?: boolean;
  position: PositionGanttItemType;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  readonly: boolean;
  projectData: Project;
  closedInTime?: boolean;
  tooltip?: string;
}

const MilestoneGanttItem = ({
  data,
  position,
  type,
  onClick,
  readonly,
  projectData,
  overdue = false,
  lastDateType = undefined,
  closedInTime = false,
  tooltip = undefined,
}: MilestoneGanttItemProps) => {

  const [{isDragging}, dragRef, preview] = useDrag(() => ({
    type: type,
    item: {
      data,
      type
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      };
    },
  }), [type, data]);

  useEffect(() => {
    preview(getEmptyImage(), {captureDraggingState: true});
  }, [type, data]);

  return (
    <MilestoneGanttItemPure
      item={data}
      dragRef={dragRef}
      left={position.x}
      top={position.y + SAUSAGE_TOP}
      width={position.width}
      opacity={isDragging ? 0 : 1}
      onClick={onClick}
      readonly={readonly}
      type={type}
      overdue={overdue}
      lastDateType={lastDateType}
      projectData={projectData}
      closedInTime={closedInTime}
      tooltip={tooltip}
    />
  );
};

export default MilestoneGanttItem;