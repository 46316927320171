import React from "react";
import { XYCoord } from "react-dnd";

import { CalendarType, ItemType } from "@/components/Gantt/const";
import { GanttItemPure } from "@/components/Gantt/elements/GanttItem";
import { calcAppendWith, calcOffset, getSausageWidth } from "@/components/Gantt/util/utils";
import { getDictCodeById, parseDate } from '@/utils';
import { checkMilestone } from '@/pages/CreateProject/Blocks/utils';
import { WorkStatus } from '@/config/const';
import { useAppSelector } from '@/store';

const getItemStyles = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  type: ItemType,
  calendarType: CalendarType,
  sausageWidth?: number
) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const transformPosition = calcOffset(initialOffset, currentOffset, type, calendarType, sausageWidth);
  const transform = `translate(${transformPosition.x}px, ${transformPosition.y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};

const CustomDragGanttItem = ({ item, initialOffset, currentOffset, calendarType }) => {
  const workStatusDict = useAppSelector(state => state.dict.workStatus);
  const isSuccess = WorkStatus.SUCCESS === getDictCodeById(workStatusDict, item.data.statusId);
  const itemDateEndSausage = !checkMilestone(item.data) && isSuccess && item.data.dateEndFact
    ? item.data.dateEndFact
    : item.data.dateEnd;
  const sausageWidth = getSausageWidth(parseDate(item.data.dateStart), parseDate(itemDateEndSausage), calendarType);
  const appendWidth = (calcAppendWith(initialOffset, currentOffset, item.type, calendarType, sausageWidth) || 0);

  return (
    <div style={getItemStyles(initialOffset, currentOffset, item.type, calendarType, sausageWidth)} >
      <GanttItemPure
        item={item.data}
        width={sausageWidth + appendWidth}
      />
    </div>
  );
};

export default CustomDragGanttItem;