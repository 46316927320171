import Checkbox from "@/elements/Checkbox";
import Select from "@/elements/Select";
import { formatDateWithFormat, getDict, getDictByCode, getDictForSelect } from "@/utils";
import SelectInTable from "@/elements/Select/SelectInTable";
import { Link } from "react-router-dom";
import i18n from "i18next";
import Agreement from "@/pages/CreateProject/Agreement/card";
import React, { useEffect, useState } from "react";
import { useAppSelector } from '@/store';
import service from "@/services";
import { compact, nth, sortBy } from "lodash";
import { ProjectSection } from "@/config/const";

const getVersionsSelect = (versions, statusDict) => {
  return versions.map((version) => {
    const date = version.dateAgreement || version.dateUpdate;

    return ({
      value: version.id,
      label: compact([
        formatDateWithFormat(date, 'DD.MM.YY'),
        `v.${version.version}`,
        getDict(statusDict, version.statusId)
      ]).join(' ')
    });
  });
}

interface IVersionFormProps {
  section?: ProjectSection;
  isShowSectionFilter?: boolean;
}

const VersionForm = ({ section, isShowSectionFilter = true }: IVersionFormProps) => {
  const [projectId, dicts] = useAppSelector(state => [
    state?.NewProject?.newProjectData?.projectId,
    state.dict
  ]);
  const [projectVersions, setProjectVersions] = useState([]);
  const [diff1, setDiff1] = useState();
  const [diff2, setDiff2] = useState();
  const [isOnlyUpdate, setOnlyUpdate] = useState(false);
  const [availSections, setAvailSections] = useState([]);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (!projectId) {
      return;
    }

    service.get(`/project/getProjectVersions/${projectId}`).then(data => {
      const versionsSort = sortBy(data.versions, 'version');
      setProjectVersions(versionsSort);

      if (versionsSort.length > 2) {
        setDiff1(nth(versionsSort, -3)?.id)
        setDiff2(nth(versionsSort, -2)?.id)
      } else {
        setDiff1(nth(versionsSort, 0).id)
        setDiff2(nth(versionsSort, 1).id)
      }
    })
  }, [projectId]);

  useEffect(() => {
    if (!dicts.agreeSection.isLoad) {
      return;
    }

    if (section) {
      const sectionId = getDictByCode(dicts.agreeSection, section).id;
      setSections([sectionId]);
      setAvailSections([sectionId]);
    } else {
      setAvailSections(dicts.agreeSection.data.filter(item => item.isActive).map(item => item.id));
    }
  }, [section, dicts.agreeSection]);

  return (
    <>
      <div className="wrapper-option">
        <Checkbox checked={isOnlyUpdate} onChange={(e) => setOnlyUpdate(e.target.checked)}>
          Отобразить только изменения
        </Checkbox>

        {isShowSectionFilter && (
          <Select options={getDictForSelect(dicts.agreeSection).filter(item => availSections.includes(item.value))}
                  label='Фильтр по разделам'
                  value={sections}
                  isMulti
                  onChange={setSections} />
        )}
      </div>

      <div className="agreement-wrapper custom-scrollbar">
        <table className='table-diff table-diff-header'>
          <tbody>
          <tr className='table-diff-row table-header tr-title'>
            <td className='title title-color'>
              Версии проекта
            </td>
            <td className='data title-color'>
              <Link to={`/${i18n.t('base')}/${diff1}`} className="link title-color no-bottom-border">Перейти к этой версии</Link>
              <SelectInTable
                widthInput={'100%'}
                maxWidthInput={270}
                widthMenu={'100%'}
                maxWidthMenu={270}
                options={getVersionsSelect(projectVersions, dicts.status)}
                label="Версия"
                value={diff1}
                onChange={setDiff1}
                backgroundColor='rgba(16, 24, 40, 0.05)'
                border=''
                textColor='rgba(16, 24, 40, 1)'
              />
            </td>
            <td className='data title-color' style={{width: '299px'}}>
              <Link to={`/${i18n.t('base')}/${diff2}`} className="link title-color no-bottom-border">Перейти к этой версии</Link>
              <SelectInTable
                widthInput={'100%'}
                maxWidthInput={270}
                widthMenu={'100%'}
                maxWidthMenu={270}
                options={getVersionsSelect(projectVersions, dicts.status)}
                label="Версия"
                value={diff2}
                onChange={setDiff2}
                backgroundColor='rgba(16, 24, 40, 0.05)'
                border=''
                textColor='rgba(16, 24, 40, 1)'
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <Agreement
        isOnlyUpdate={isOnlyUpdate}
        activeVersionId={diff1}
        newVersionId={diff2}
        isDiff={projectVersions.length > 1}
        dict={dicts}
        sections={sections}
        availSections={availSections}
      />
    </>
  );
}

export default VersionForm;